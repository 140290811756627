/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef, Children } from 'react'
import { Button, Toast, Popup, Image, Checkbox, Divider, Modal } from 'antd-mobile'
import styles from './authorize-commom-page-three.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { isIOS, isAndroid, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import copy from 'copy-to-clipboard'
import { UserInfo } from '@/types/apis/user'
import { ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import indexApi from '@/apis'
import ProtocolIdsConfirm1 from '@/pages/fill-information/components/protocolIds-confirm-1/protocolIds-confirm-1'
import ProtocolIdsConfirm2 from '@/pages/fill-information/components/protocolIds-confirm-2/protocolIds-confirm-2'
import useFirstOrAfter from '@/hooks/use-first-or-after'
import ApiToH5Skin from './components/api-to-h5-skin/api-to-h5-skin'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
import moment from 'moment'
import indexApiInfo from '@/apis/index'
import InstitutionList from '../institution-list/institution-list'
import { RightOutline, CheckCircleFill, CloseCircleOutline } from 'antd-mobile-icons'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'
import ProtocolList from './components/protocol-list/protocol-list'
import { CompanyType } from '@/constants/common'

interface Props{ }

interface ProtocolType {
  id: string,
  name: string | null,
  protocolType: number | null
  partnerProtocolList?:any
}

interface InstitutionProduct {
  id: number,
  productCompany: string | null,
  productName: string | null,
  partnerProtocolList:ProtocolType[] | null
}

export interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt: string
  identity:string
}


const Authorize: React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const ProtocolIdsConfirm1ModalRef: any = useRef()
  const [search] = useSearchParams()
  const userInfo = useAppSelector(selectUserInfo)

  const [details, setDetails] = useState<any>({})
  const [rqResult, setRqResult] = useState<any>()
  const [otherProtocols, setotherProtocols] = useState([])
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [agreementContent, setAgreementContent] = useState('')
  // 是否显示弹出层
  const [protocolIdsVisible, setProtocolIdsVisible] = useState(false)
  // 如果是匹配多产品，则显示匹配的多产品协议列表
  const [institutionProtocol, setInstitutionProtocol] = useState<any>([])
  // 当前勾选的多产品
  const [checkInstitution, setCheckInstitution] = useState<number[]>([])
  // 随机服务人数
  const [randomNums, setRandomNums] = useState<{ serviceNum: number | null, servicePersent: number | null }>({
    serviceNum: null,
    servicePersent: null
  })
  const [loginInfo, setLoginInfo] = useState<LoginInfo>()
  // 显示挽留弹窗
  const [showRetainPopup, setShowRetainPopup] = useState(false)

  const navigate = useNavigate()

  useFirstOrAfter(() => {
    localStorage.removeItem('productBack')
    if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
      Storage.set('isRefreshLoadPage', true)
      // window.location.href = `${document.URL}&refresh=1`
      return
    } else {
      Storage.set('isRefreshLoadPage', false)
    }
    // 监听浏览器回退事件
    window.addEventListener('popstate', back, false)
    // window.onpopstate = back
    window.history.pushState(null, 'null', document.URL)

    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
    }
  }, () => {
  // eslint-disable-next-line no-console
  }, [])

  // 打开挽留弹窗
  const openProtocolIdsConfirm1Modal = () => {
    let tempYouqianlaiProtocol = ''
    let temPotherProtocols:any = []
    // eslint-disable-next-line array-callback-return
    rqResult.protocols?.map((item:any) => {
      if (item.protocolType !== 1) {
        temPotherProtocols.push(item)
      } else if (item.protocolType === 1) {
        tempYouqianlaiProtocol = item
      }
    })
    ProtocolIdsConfirm1ModalRef.current.init({
      quota: details.applyLimit >= 3 ? '100,000' : '50,000',
      productName: rqResult.productName,
      youqianlaiProtocol: tempYouqianlaiProtocol,
      otherProtocols: temPotherProtocols,
      productId: rqResult.id,
    })
  }

  // 返回
  const back = () => {
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        navigate(-1)
      } else {
        //  阻止浏览器回退事件
        openProtocolIdsConfirm1Modal()
        Storage.set('isRefreshLoadPage', false)
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const [token, setToken] = useState('')
  const [apiChannelCode, setApiChannelCode] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [jumpUrl, setJumpUrl] = useState('')
  // 当前操作第几回授权
  const [authNum, setAuthNum] = useState(0)
  let urlRqResult: any = search.get('rqResult')
  const [urlEstimatedAmount, setUrlEstimatedAmount] = useState('50000')
  // 前端神策埋点 - 访问授权页
  const sensorsVisitAuthorizePage = (type: SensorsType.visit_authorization_page | SensorsType.click_authorize, checkedProduct?: number[]) => {
    try {
      const pageOptions: any = search.get('options')
      let tempDetails
      try {
        tempDetails = JSON.parse(decodeURIComponent(pageOptions)) || {}
      } catch (err) {
        tempDetails = JSON.parse(pageOptions) || {}
      }

      let productList = []
      let selectProductList = []
      if (tempDetails.rqResult?.thirdpartyType === 7) {
        productList.push(`${tempDetails.rqResult.productName}-${tempDetails.rqResult.yqlWholeProcessBumpVO?.partnerProductName}`)
        selectProductList.push(`${tempDetails.rqResult.productName}-${tempDetails.rqResult.yqlWholeProcessBumpVO?.partnerProductName}`)
      } else {
        productList.push(tempDetails.rqResult?.productName)
        selectProductList.push(tempDetails.rqResult?.productName)
      }

      if (tempDetails.rqResult?.mayAuthProductList?.length > 0) {
        tempDetails.rqResult?.mayAuthProductList.forEach((element:any) => {
          if (element.thirdpartyType === 7) {
            productList.push(`${element.productName}-${element.partnerProductName}`)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(`${element.productName}-${element.partnerProductName}`)
            }
          } else {
            productList.push(element.productName)
            if (checkedProduct && checkedProduct.includes(element.id)) {
              selectProductList.push(element.productName)
            }
          }
        })
      }
      let updateInfo:any = {
        ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        mobile: userInfo?.mobile || localStorage.getItem('user_phone'),
        h5_matched_product_list: productList.join(';'),
        h5_matched_product_count: productList.length
      }
      if (type === SensorsType.visit_authorization_page) {
        sensors.track(type, updateInfo)
      } else {
        updateInfo.h5_matched_product_selected_list = selectProductList.join(';')
        sensors.track(type, updateInfo)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    let urlApiChannelCode:any = search.get('apiChannelCode')
    let urlSerialNo:any = search.get('serialNo')
    let urlToken:any = search.get('token')
    let urlJumpUrl: any = search.get('jumpUrl')

    //设置authNum
    let urlAuthNum: any = search.get('authNum')
    if (urlAuthNum) {
      setAuthNum(Number(urlAuthNum))
    }
    if (urlApiChannelCode) {
      setSerialNo(urlSerialNo)
      setApiChannelCode(urlApiChannelCode)
      localStorage.setItem('ad_channel_code', urlApiChannelCode)
      if (urlJumpUrl) {
        const tempJumpUrl = JSON.parse(decodeURIComponent(urlJumpUrl))
        setJumpUrl(tempJumpUrl)
      }
      if (urlToken) {
        Storage.set(TOKEN, urlToken)
        setToken(urlToken)
      }
    } else {
      const pageOptions: any = search.get('options')
      let tempDetails
      let checkProduct
      try {
        tempDetails = JSON.parse(decodeURIComponent(pageOptions)) || {}
      } catch (err) {
        tempDetails = JSON.parse(pageOptions) || {}
      }
      if (tempDetails?.estimatedAmount) {
        setUrlEstimatedAmount(tempDetails.estimatedAmount)
      }

      // 如果是多产品分发，那么给产品默认添加勾选
      if (tempDetails.rqResult?.mayAuthProductList?.length > 0) {
        checkProduct = tempDetails.rqResult?.mayAuthProductList?.map((product: InstitutionProduct) => {
          return product.id
        })
        setCheckInstitution(checkProduct)
      }
      setDetails(tempDetails)
      setRqResult(tempDetails.rqResult)
    }
    // 前端神策埋点 - 用户访问授权页
    sensorsVisitAuthorizePage(SensorsType.visit_authorization_page)
  }, [])

  // 应用在API采量授权页重刷的场景
  const reVisitApiAuthPage = () => {
    if (urlRqResult) {
      const tempRqResult = JSON.parse(decodeURIComponent(urlRqResult))
      setRqResult(tempRqResult)
      setDetails({
        applyLimit: tempRqResult.applyLimit
      })
    }
  }

  // 如果是麦吉优则修改
  useEffect(() => {
    let newProtocols: any = []
    let ProtocolTreeList: any = []
    // 获取产品列表中产品详情的随机数据
    getRandomNum()
    // eslint-disable-next-line array-callback-return
    rqResult && rqResult.protocols?.map((item: any, index: number) => {
      if (item.protocolType !== 1) {
        newProtocols.push(item)
        ProtocolTreeList.push(item)
      } else if (item.protocolType === 1) {
        if (channelValue?.includes('mjy') || channelValue?.includes('hbjf')) {
          item.name = '用户个人信息共享授权协议'
        }
        // 如果是聚客融渠道的联登协议，需要将协议名改为聚客融联登协议
        if (channelValue?.includes('jufu') && item.name === '有钱来联登默认协议') {
          item.name = '聚客融联登默认协议'
        }
        setYouqianlaiProtocol(item)
      }
    })

    // 针对有钱来全流程类型的合作方式，需要把第三方协议并入合作机构协议中展示
    if (rqResult && rqResult.thirdpartyType === 7) {
      if (rqResult.yqlWholeProcessBumpVO && rqResult.yqlWholeProcessBumpVO.partnerProtocolList) {
        newProtocols = [
          ...newProtocols,
          ...rqResult.yqlWholeProcessBumpVO.partnerProtocolList,
        ]
        ProtocolTreeList.push({
          id: Math.random().toString(36),
          name: rqResult.productName || '合作机构协议',
          protocolList: rqResult.yqlWholeProcessBumpVO.partnerProtocolList,
        })
      }
    }
    // 聚客融渠道不展示合作机构个人信息授权书
    if (ProtocolTreeList.length > 0 && channelValue?.includes('jufu')) {
      ProtocolTreeList = ProtocolTreeList.filter((item:ProtocolType) => item?.name !== '合作机构个人信息授权书')
      newProtocols = newProtocols.filter((item:ProtocolType) => item?.name !== '合作机构个人信息授权书')
    }
    setInstitutionProtocol(ProtocolTreeList)
    setotherProtocols(newProtocols)
  }, [rqResult])

  useEffect(() => {
    async function getAgreementInfo() {
      const res: any = await indexApi.getAgreementInfo(youqianlaiProtocol.id, rqResult.id)
      console.log(res)

      if (channelValue?.includes('jufu')) {
        // 如果是快手渠道，将公司主题改为广州市聚富互联网小额贷款有限公司
        if (channelValue?.includes('jfxd')) {
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, CompanyType.JFXD_COMPANY.company)
        } else {
          res.content = res.content.replace(/广州虫洞跳跃信息科技有限公司|广州市虫洞科技有限公司/g, CompanyType.JKR_COMPANY.company)
        }
        // 聚客融渠道，h5类型，将联登协议内容进行修改
        if (rqResult.thirdpartyType === 1) {
          res.content = res.content.replace(/广州海珠/g, '深圳龙华')
          // 在【】前面插入【广州市虫洞科技有限公司】
          res.content = res.content.replace(/传输给\s*/, '传输给【广州市虫洞科技有限公司】')
        }
      }
      setAgreementContent(res.content)
    }

    if (youqianlaiProtocol) {
      getAgreementInfo()
    }
  }, [youqianlaiProtocol])

  useEffect(() => {
    async function getFomrSubmitStatus() {
      const res:any = await indexApi.getFomrSubmitStatus({
        adChannelCode: apiChannelCode,
        serialNo
      })
      // 如果用户已经授权过，并且第二回授权没有结果，跳转到上回结果页
      if (res.submitStatus === 10 && res.rematchResult !== 3) {
        const reMatchResult:any = res
        let tempOptions:any = {}
        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = 0
        tempOptions.revisit = true // 标记为再访
        window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
        return
      }

      setDetails({
        applyLimit: res.applyLimit
      })
      setRqResult(res)
    }

    // 说明当前路由已被修改，从URl中获取修改后的数据
    if (apiChannelCode && authNum > 0) {
      reVisitApiAuthPage()
    } else if (token && apiChannelCode) {
      getFomrSubmitStatus()
      let devicePlatForm:any = 3
      if (isAndroid) {
        devicePlatForm = 1
      }
      if (isIOS) {
        devicePlatForm = 2
      }
      indexApi.reportPhoneType(devicePlatForm)
    }

  }, [token, apiChannelCode, serialNo])

  // 获取用户信息
  const getLoginInfo = async() => {
    let fillInformation = localStorage.getItem('fillInformation')
    let useInfo = fillInformation && JSON.parse(fillInformation)
    if (useInfo) {
      setLoginInfo(useInfo)
    } else {
      const res: any = await indexApi.getApplyStatus()
      setLoginInfo(res?.data)
    }
  }

  // 获取用户信息
  useEffect(() => {
    getLoginInfo()
  }, [])

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)

  // 跳转页面
  // const openOtherView = (url: string) => {
  //   Storage.set(ENTER_AGREEMENT, true)
  //   window.location.href = url
  // }

  // 授权提交
  const submitApplication = async() => {
    try {
      let protocolIds = rqResult.protocols?.map((item:any) => {
        return item.id
      })

      // 如果匹配到的是H5产品，则走H5产品的申请、联登流程
      if (rqResult.thirdpartyType === 1) {
        handleH5Product(rqResult.id, 0, protocolIds)
        return
      }
      setBtnLoading(true)
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${details.adChannelCode || apiChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 前端神策埋点 - 用户点击授权
      sensorsVisitAuthorizePage(SensorsType.click_authorize, checkInstitution)
      // 信息授权
      const res:any = await indexApi.confirmAuthorize({
        productId: rqResult.id,
        protocolIds,
        agree: true,
        adChannelCode: details.adChannelCode,
        isHuaWeiPhone: details.isHuaWeiPhone,
        apiChannelCode,
        orderNum: authNum, //第几回授权
        serialNo: rqResult.serialNo || serialNo,
        productIds: checkInstitution
      })
      setBtnLoading(false)
      handleAduitSuccess(res)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  // 处理H5产品逻辑
  const handleH5Product = async(productId:any, entranceType:number, protocolIds:any) => {
    // 申请产品
    let applyProductRes:any = await indexApi.applyFor({
      productId,
      entranceType,
      appH5: 2
    })
    // 判断是否需要联登
    if (applyProductRes?.unionLoginFlag === 1) {
      try {
        const authH5ProductRes:any = await indexApi.authH5Product({
          productId,
          protocolIds: `${protocolIds}${''}`,
          agree: true,
          entranceType,
          appH5: 2
        })
        if (authH5ProductRes) {
          window.location.href = authH5ProductRes
        } else {
          navigate(`/fill-information/success-page?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
        }
      } catch (error) {
        navigate(`/fill-information/success-page?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`)
      }
    } else {
      window.location.href = applyProductRes.thirdpartyTargetUrl
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:any, tempAuthNum: any) => {
    const pageUrl = window.location.origin + window.location.pathname
    let urlOptions = ''
    // 如果是API采量授权方式
    if (apiChannelCode) {
      let modifiedUrl = `${pageUrl}?apiChannelCode=${apiChannelCode}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&authNum=${tempAuthNum}&rqResult=${encodeURIComponent(JSON.stringify(newRqResult))}&serialNo=${serialNo}`
      window.history.replaceState(null, 'null', modifiedUrl)
    } else {
      urlOptions = {
        ...details,
        rqResult: newRqResult,
      }
      window.history.replaceState(null, 'null', `${pageUrl}?options=${encodeURIComponent(JSON.stringify(urlOptions))}&authNum=${tempAuthNum}`)
    }
  }

  // 这里阐述一下，如果撞库通过则有以下三种情况：
  // 撞库通过并且携带jumpUrl，则跳转到jumpUrl指定的页面
  // 如果是APi转H5，则直接跳转第三方链接
  // 如果授权失败，但是返回了下一个产品就重新授权，这里面针对匹配回调类型thirdpartyType === 5进行了单独处理，否则重新匹配进入此页面
  // 如果以上三种情况都不属于，则直接跳转到成功下载页面，成功页面也会进行判断，如果是分期乐渠道，是没有下载按钮的
  const handleAduitSuccess = async(res: any) => {
    // 正常授权且有结果(撞库通过)
    if (res && res.applyStatus !== 2) {
      // 如果url上有携带跳转地址jumpUrl，则跳转
      if (jumpUrl) {
        res.jumpUrl = jumpUrl
        navigate(`/fill-information/success-transfer?options=${encodeURIComponent(JSON.stringify(res))}`)
        return
      }
      // 如果是API转H5类型，则直接跳转第三方链接
      if (res.thirdpartyType === 4 && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: rqResult.id,
          productName: rqResult.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }

      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (rqResult.serialNo) {
          res.productNextMatchDTO.serialNo = rqResult.serialNo
        }
        // 如果是其它产品，则更新本页面信息
        setRqResult(res.productNextMatchDTO)
        setAuthNum(res.productNextMatchDTO.orderNum)
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO, res.productNextMatchDTO.orderNum)
        return
      }
      navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify(res))}&isMultiProduct=${checkInstitution.length > 0}`)
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options:any = res ? res : { rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }
    navigate(`/fill-information-three/success-page?options=${encodeURIComponent(JSON.stringify(options))}&isMultiProduct=${checkInstitution.length > 0}`)
  }

  // 弹起协议相关列表
  const openProtocolsPopup = () => {
    setProtocolIdsVisible(true)
  }


  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  const checkOtherProtocols = (item: any) => {
    if (item.id) {
      navigate(`/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${rqResult.id}`)
    } else if (item.url) {
      window.location.href = item.url
    }
  }

  // 生成随机数范围
  const randomNum = (minNum:number, maxNum:number) => {
    return parseInt((Math.random() * (maxNum - minNum + 1) + minNum).toString(), 10)
  }

  // 随机生成数字，并存储本地防止刷新数据变化太大
  const getRandomNum = () => {
    let list = localStorage.getItem('randomNumList')
    let randomNumList
    if (list) {
      randomNumList = JSON.parse(list)
    } else {
      randomNumList = {
        serviceNum: randomNum(15000, 30000),
        servicePersent: randomNum(950, 1000) / 10
      }
      localStorage.setItem('randomNumList', JSON.stringify(randomNumList))
    }
    setRandomNums(randomNumList)
  }

  // 跳转到挽留页面
  const goToRetainPage = () => {
    navigate(`/fill-information-three/retain-page?options=${encodeURIComponent(JSON.stringify(details))}`)
  }

  return (
    <section className={classNames([styles.authorizePage, channelValue.includes('jufu') && styles.jufuAuthorizePage])}>
      <Header />
      <div className={styles.authorizeMainBox}>
        {/* 预估额度 */}
        <div className={styles.creditLimit}>
          <div className={styles.creditLimitTitle}>
            <CheckCircleFill color="#ffffff" fontSize={24} style={{ marginRight: '13px' }}/>
            <span>{ loginInfo?.realName || ''}！您的初审预估额度为（元）：</span>
          </div>
          <div className={styles.creditNum}>{ urlEstimatedAmount}.00</div>
        </div>
        <div className={styles.productCard}>
          <div className={styles.productCardTitleBox}>
            <div className={styles.line1}></div>
            <div className={styles.introduceTitle}>本次额贷款服务提供方</div>
            <div className={styles.line2}></div>
          </div>
          <div className={styles.multipleProcessContentItem}>
            <div className={styles.multipleProcessContentItemLeft}>
              <div>
                {
                  rqResult?.thirdpartyType !== 7 || !rqResult.yqlWholeProcessBumpVO?.partnerLogo ? <>
                    <img className={styles.contentItemIcon} src={require('@imgs/information/loan-platform.png')} />
                  </> : <>
                    <Image className={styles.contentItemIcon} fit="cover" style={{
                      marginRight: '10px',
                      borderRadius: 6
                    }}
                    src={rqResult.yqlWholeProcessBumpVO?.partnerLogo}
                    fallback={<img className={styles.contentItemIcon}
                      alt="contentItemIcon"
                      src={require('@imgs/information/loan-institutions.png')}
                    />}
                    />
                    {/* <img className={styles.contentItemIcon} src={rqResult.yqlWholeProcessBumpVO?.partnerLogo} /> */}
                  </>
                }
              </div>
              {
                channelValue?.includes('noListMainBody')
                  ? <div className={styles.multipleProcessContentItemInfo}>
                    <span>
                      { rqResult?.thirdpartyType === 7 ? rqResult.productName : '有钱钱包' }
                    </span>
                    <div>
                      {
                        rqResult?.thirdpartyType === 7
                          ? <>{rqResult.yqlWholeProcessBumpVO?.partnerProductName ?? ''}</>
                          : <>{rqResult.productName ?? ''}</>
                      }
                    </div>
                  </div>
                  : <div className={styles.multipleProductInfo}>
                    <div className={styles.multipleProductInfoTop}>
                      <span className={styles.productName}>
                        {rqResult?.thirdpartyType === 7 ? rqResult.yqlWholeProcessBumpVO?.partnerProductName ?? '' : rqResult?.productName ?? ''}
                      </span>
                      {/* { rqResult?.thirdpartyType === 7
                          && <div className={styles.platformName}>服务平台：{ rqResult?.productName}</div>
                      } */}
                    </div>
                    <span className={styles.partnerCompanyName}>
                      {rqResult?.thirdpartyType === 7 ? rqResult.yqlWholeProcessBumpVO?.partnerCompanyName ?? '' : rqResult?.productCompany ?? ''}
                    </span>
                  </div>
              }
            </div>
          </div>
          <Divider />
          <div className={styles.productIntroduce}>
            <div className={styles.productIntroduceItem}>
              <div className={styles.productInfo}>{randomNums.serviceNum}</div>
              <div className={styles.productName}>服务人数</div>
            </div>
            <Divider style={{ height: '30px' }} direction="vertical"/>
            <div className={styles.productIntroduceItem}>
              <div className={styles.productInfo}>车 房 公积金</div>
              <div className={styles.productName}>擅长领域</div>
            </div>
            <Divider style={{ height: '30px' }} direction="vertical"/>
            <div className={styles.productIntroduceItem}>
              <div className={styles.productInfo}>{randomNums.servicePersent}%</div>
              <div className={styles.productName}>服务人数</div>
            </div>
          </div>
          <div className={styles.protocolIntroduce}>
            <span className={styles.protocolIntroduceTips}>特别提示：</span>
            <span>我们会根据您的综合资信状况，为您匹配专属贷款服务机构，在咨询前请您务必仔细阅读并知晓</span>
            <span
              className={styles.footerTextRed}
              onClick={() => {
                navigate(`/jkr-general-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}&company=${rqResult?.productCompany || rqResult.yqlWholeProcessBumpVO?.partnerCompanyName}`)
              }}
            >
                《通用授权协议》
            </span>
            <span
              className={styles.footerTextRed}
              onClick={() => {
                navigate(`/jkr-student-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}`)
              }}
            >
                《非大学生承诺函》
            </span>
            <span
              className={styles.footerTextRed}
              onClick={() => {
                navigate(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${rqResult.id}&protocolType=${channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? 1 : 0}`)
              }}
            >
                《{youqianlaiProtocol.name}》
            </span>
            {
              otherProtocols.length > 0 && <span
                className={styles.footerTextRed}
                onClick={() => {
                  openProtocolsPopup()
                }}
              >
                    《机构相关协议》
              </span>
            }
          </div>
          <div className={classNames([styles.maskBox, channelValue.includes('jufu') && styles.jufuMaskBox])}>
            <img src={require(`@/assets/imgs/fill-infomation-three/${!channelValue.includes('jufu') ? 'yqqb-' : ''}card-bg.png`)} alt="" />
          </div>
        </div>
        <div className={styles.protocolListBox} >
          <ProtocolList loginInfo={loginInfo} title={youqianlaiProtocol?.name} company={rqResult?.productCompany}>
            <div className={styles.agreementContent} dangerouslySetInnerHTML={{ __html: agreementContent }} ></div>
          </ProtocolList>
        </div>
        <div className={styles.footerButtonBox}>
          <div className={styles.btnBox}>
            <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} color="primary" onClick={submitApplication}>
              { '同意协议 领取额度'}
            </Button>
          </div>
          <div className={styles.cancleBtn} onClick={() => setShowRetainPopup(true)}>
          放弃额度
          </div>
        </div>
      </div>

      {/* 表单校验失败提示弹窗 */}
      <Modal
        visible={showRetainPopup}
        content={(<div className={styles.retainBox}>
          <img style={{ width: '128px' }} src={require('@/assets/imgs/fill-infomation-three/retain-popup.png')} alt="" />
          <span className={styles.warningText}>差一步可领取额度，别错过！</span>
          <Button onClick={() => setShowRetainPopup(false)} size="large" color="primary" className={styles.continueBtn}>继续领取</Button>
          <div className={styles.giveUpBtn} onClick={goToRetainPage}>强行错过</div>
        </div>)}
        showCloseButton={false}
        bodyClassName={styles.retainModal}
      />

      <Popup
        visible={protocolIdsVisible}
        showCloseButton={true}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
        }}
        onMaskClick={() => {
          setProtocolIdsVisible(false)
        }}
        onClose={() => {
          setProtocolIdsVisible(false)
        }}
      >
        <div className={styles.protcolPopup}>
          <div className={styles.popupTitle}>协议列表</div>
          <div className={styles.protocolIdsBox}>
            {
              otherProtocols?.map((item: any) => {
                return (
                  <div
                    className={styles.protocolIdItem}
                    onClick={() => checkOtherProtocols(item)}
                    key={item.name}
                  >
                    <div>{ `${item.name}` }</div>
                    <img className={styles.arrowBlack} src={require('@imgs/information/arrow-black.png')} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </Popup>
    </section>
  )
}

export default Authorize