/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import htmlToJsx from 'html-to-react'
import Header from '@/components/header/header'
import styles from './clarification-announcement.module.scss'
import classNames from 'classnames'
import { getParams } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { CompanyType } from '@/constants/common'

interface Props{}
// 澄清公告
const RegisterAgreement:React.FC<Props> = props => {
  const hostNameObj = getHostNameObj()

  const [isShowHeader, setIsShowHeader] = useState(false)
  const [name] = useState(hostNameObj.appName)

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
  }, [])

  // 有钱来注册协议
  const youqianlaiRegister = () => {
    return (
      <>
        <p className={styles.title}>澄清公告</p>
        <p>尊敬的用户，</p>
        <p>
          您好！由于iOS端“有钱来” App的URL Scheme设置问题，导致iOS用户部分手机App或浏览器中选择“打开支付宝”时会被直接引导跳转至“有钱来” App。
        </p>
        <p>
          为了解决这一错误跳转问题，降低对支付宝造成的负面影响，我们已针对iOS端 “有钱来” App紧急进行了升级更新处理，完成了相应的问题修复。同时，为了避免上述问题对您的使用体验造成影响，请您尽快升级更新您设备终端内的“有钱来” App 版本。
        </p>
        <p>对您和支付宝所造成的不便和困扰，我们在此进行致歉并予以澄清。</p>
        <div className={styles.emptyLine}></div>
        <div className={styles.alignRight}>
          <p>{ CompanyType.ZAXY_COMPANY.company}</p>
          <p>2023年6月5日</p>
        </div>

        <div className={styles.emptyLine}></div>
      </>
    )
  }

  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="澄清公告" />
      }
      {
        youqianlaiRegister()
      }
    </div>
  )
}

export default RegisterAgreement