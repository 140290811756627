// 加载页面
import React, { useState, useEffect } from 'react'
import styles from './loading-page.module.scss'
import Header from '@/components/header/header'
import Icon from '@/components/icon/icon'
import { CheckCircleFill } from 'antd-mobile-icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface Props {}

const LoadingPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [progressPercentage, setProgressPercentage] = useState(0)
  // 加载进度
  const [processLoading, setProcessLoading] = useState(0)


  let loadingList = [
    {
      label: '实名认证',
      value: 1
    },
    {
      label: '资产信息',
      value: 2
    },
    {
      label: '匹配成功',
      value: 3
    }
  ]

  useEffect(() => {
    // 加载进度条
    const interval = setInterval(() => {
      setProgressPercentage(prev => {
        if (prev >= 100) {
          clearInterval(interval)
          return 100
        }
        return prev + 1
      })
    }, 30)

    const intervalLoading = setInterval(() => {
      setProcessLoading(prev => {
        if (processLoading >= 3) {
          clearInterval(intervalLoading)
          return 3
        }
        return prev + 1
      })
    }, 900)

    setTimeout(() => {
      navigate(`/fill-information-three?adChannelCode=${adChannelCode}`)
    }, 3500)

    return () => {
      clearInterval(interval)
      clearInterval(intervalLoading)
    }
  }, [])
  return (
    <div className={styles.loadingPage}>
      <Header />
      <div className={styles.loadingImageBox}>
        <img className={styles.loadingImage} src={require(channelValue?.includes('jufu') ? '@/assets/imgs/fill-infomation-three/loading.gif' : '@/assets/imgs/fill-infomation-three/yqqb-loading.gif')} alt="" />
      </div>
      <div className={styles.progress}>
        <div className={styles.progressBar}>
          <div className={styles.progressLine} style={{ transform: `translate(${-100 + progressPercentage}%)` }}></div>
        </div>
        <div className={styles.progressPercentage} style={{ left: `${progressPercentage - 11 > 0 ? progressPercentage - 11 : (progressPercentage - 11) / 2}%` }}>{progressPercentage}%</div>
      </div>
      <div className={styles.loadingText}>
      资金匹配中，请稍后...
      </div>
      <div className={styles.loadingProcess}>
        {
          loadingList.map(item => {
            return (
              <div key={item.value} className={styles.loadingProcessBox}>
                {
                  processLoading >= item.value
                    ? <CheckCircleFill className={styles.checkIcon} fontSize={24}/>
                    : <div className={styles.loadingIconBox} style={{ transform: `rotate(${progressPercentage * 10}deg)` }}>
                      <Icon type="loading" className={styles.loadingIcon} size={24} />
                    </div>
                }
                <span className={styles.text}>{ item.label}</span>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default LoadingPage
