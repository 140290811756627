/**
 *@author Wj
 *@date 2024/7/24
 *@Description:渠道统一管理
 */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Value } from 'sass'

export interface adChannelState {
    adChannelCode: string,
    isSpecialChannel: boolean,
    specialCode: Record<string, { value: string, code: string[] }>,
    channelValue: string[],
 }

/**
  * 特殊渠道定制
  * @value hbjf
  * @Infomation 享呗金服v0.1.40 - 特殊渠道定制
  * @intruduce FG9fGQ是正式渠道，7VU2Ln是测试渠道
  * @intruduce 内容与麦吉优渠道一样，在麦吉优的渠道基础上增加了部分新增处理，如下：
  * @intruduce 去掉页面title的有钱钱包
  * @intruduce 最高可借200000元”处，需要增加一条说明“具体金额以审批结果为准
  * @intruduce “借1000元用1天0.2元起”改为“年化利率7.2%起”
  * @intruduce 页面底部的“7%-24%”改为“7.2%-24%”
  * @link 产品文档:https://chongdong.feishu.cn/docx/N4DwdwDWAowligxu25BcmZCTnjh
  */

/**
   * 特殊渠道定制
   * @value mjy
   * @Infomation 麦吉优渠道v0.1.19 - 特殊渠道定制
   * @intruduce PD4Amz是正式渠道，25WbiH是测试渠道
   * @intruduce 所有页面不展示公司名称、产品名称和 ICP 备案信息
   * @intruduce 所有涉及到虫洞跳跃公司信息的，改为【本公司】
   * @intruduce header改为极速借贷
   * @intruduce 注册页和结果页不展示公司名称和logo
   * @intruduce 授权协议由于修改内容较多，直接替换原本协议
   * @link 产品文档:https://chongdong.feishu.cn/docx/IWNgdwfmwovgMQxRIuMcKDdpnXZ
   */

/**
   * 特殊渠道定制
   * @value jufu
   * @Infomation 聚客融渠道v0.1.35 - 特殊渠道定制
   * @intruduce tHH56Z是测试渠道,其余是正式渠道
   * @intruduce 主题定制，在这里需求新增了主题定制功能，将所有页面的主题色改为紫色
   * @intruduce 修改登录注册页的隐私协议和注册协议，以及将授权页的协议改为非大学生承诺函和通用授权协议
   * @intruduce header改为聚客融
   * @intruduce 授权协议由于修改内容较多，直接替换原本协议
   * @append 追加需求：将快手渠道号的改为jufu渠道显示，但是快手渠道号需要改变落地页的主体和Icp备案信息。
   * @append 追加需求：快手文案修改,以及借多久按钮选项增加。
   * @append 追加需求：'01bpDe'是腾讯正式渠道，0Men6r是腾讯测试渠道，需要更换腾讯渠道文案主体和icp备案信息。
   * @intruduce 该渠道访问地址：https://yql-h5.chongdong.cc/h5?source=BryFpJ，新增h5路由，并且添加路由参数source解析
   * @link 产品文档:https://chongdong.feishu.cn/docx/Czz1dM0vro1OfCxzTR0cigkgngr
   */

/**
   * 特殊渠道定制 - 年代比较久，没有产品文档
   * @value bdVid 百度渠道访问时会有一个bd_vid，如果是百度渠道来的，到了授权页时需要通过授权接口将它传给后端，页面没有特定展示内容
   * @intruduce 百度渠道访问时会有一个bd_vid，如果是百度渠道来的，到了授权页时需要通过授权接口将它传给后端，页面没有特定展示内容
   * @append 追加需求：百度渠道单独定制落地页。
   * @append noListMainBody -- 列表不展示公司主体
   * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=2511-1946&t=iEcT0VtX63JlIXt4-0
   * @link 产品文档:-
   */

/**
   * 特殊渠道定制
   * @value tengxun
   * @Infomation 腾讯渠道 - 特殊渠道定制
   * @intruduce 腾讯特殊渠道单独定制首页 - 只修改了底部文案和icp以及公司主体 - 无产品文档
   * @append 追加需求：'01bpDe'是腾讯正式渠道，0Men6r是腾讯测试渠道，需要更换腾讯渠道文案主体和icp备案信息。
   * @link 产品文档:https://chongdong.feishu.cn/docx/Czz1dM0vro1OfCxzTR0cigkgngr
   */

/**
    * 特殊渠道定制
    * @value kuaishou
    * @Infomation 快手渠道v0.1.26 - 特殊渠道定制
    * @intruduce CG5Gd7是测试渠道,其余是正式渠道
    * @intruduce 更改主体和备案号
    * @intruduce 登录注册页的协议更换公司主体，匹配授权页协议更换公司主体,公司主体为：’广州市聚富互联网小额贷款有限公司‘
    * @intruduce 匹配到一个或多个产品均不展示公司主体名
    * @append 追加需求：将快手渠道加入到聚客融皮肤中。
    * @link 产品文档:https://chongdong.feishu.cn/docx/InObd3Ue5oR3OSxdjxPcrKYbncg
    */

/**
   * 特殊渠道定制
   * @value formprocess3 表单流程3
   * @intruduce 快手渠道表单流程定制，整体项目流程重新定制
   * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5159-38824&node-type=frame&t=E7JkfAppc4Y1gvvF-0
   * @link 产品文档:-
   */

/**
   * 特殊皮肤定制
   * @value juhaojie - 特殊皮肤定制
   * @intruduce 修改全局的名称和logo为聚好借
   */

/**
   * 特殊皮肤定制
   * @value fenqile
   * @Infomation 分期乐 -未开始版本号 - 特殊皮肤定制
   * @intruduce 整体UI重构，内容较多,详细内容查看文档
   * @link 产品文档:https://chongdong.feishu.cn/wiki/U6lBwdyKHi6ZSIkACH0cHnM9nVb
   */

/**
   * 特殊皮肤定制
   * @value baidu
   * @Infomation 百度 -未开始版本号 - 特殊皮肤定制
   * @intruduce 整体UI重构，内容较多,详细内容查看文档
   * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=2511-1946&t=iEcT0VtX63JlIXt4-0
   * @link 产品文档:-
 */

/**
   * 特殊皮肤定制
   * @value jurongyi
   * @intruduce 修改全局的名称和logo为聚融易
   */

/**
   * 特殊皮肤定制
   * @value registerTips
   * @intruduce 底部添加贷款有风险 借款需谨慎文案
   */

/**
   * 特殊皮肤定制
   * @value hbRegisterCustomized
   * @intruduce 还呗注册页面定制，标签和按钮内容定制
   */

/**
   * 特殊皮肤定制
   * @value jiezhanggui
   * @intruduce 有钱花注册页面定制，服务协议、隐私政策、信息共享授权协议内容定制
   */

/**
   * 特殊主体定制
   * @value shenzhenJkr
   * @intruduce 修改全局的主体为深圳聚客融科技有限公司
   */

/**
   * 特殊主体定制
   * @value jfxd
   * @intruduce 修改全局的主体为广州市聚富互联网小额贷款有限公司
   */

/**
   * 特殊主体定制
   * @value zhonganXy
   * @intruduce 修改全局的主体为深圳市中安信业小额贷款有限公司
   */

/**
   * 特殊功能定制
   * @value noMate -- 不匹配直接跳转结果页展示
   */

/**
   * 特殊功能定制
   * @value backRegister -- 点击返回清空缓存回到注册页
   */

/**
   * 特殊功能定制
   * @value noListMainBody -- 列表不展示公司主体
   */

/**
   * 特殊功能定制
   * @value ksBurying -- 快手埋点
   */

/**
   * 特殊功能定制
   * @value justShowProduct -- 只展示产品名称
   */

/**
   * 特殊功能定制
   * @value baseSuccessPage -- 结果页展示基础内容
   */

/**
   * 特殊功能定制
   * @value iosDownloadyqqb -- ios下载有钱钱包apk包
   */

/**
   * 特殊功能定制
   * @value androidDownloadyqqb -- 安卓下载有钱钱包apk包
   */

/**
   * 特殊功能定制
   * @value cancleFillBtn -- 取消表单填借款额度0-3w额度。
   */

/**
   * 特殊功能定制
   * @value jkrHaierDownloadApp -- 聚客融海尔下载自己的app
   */

/**
   * 特殊功能定制
   * @value justShowProduct -- 授权页面只展示产品名称
   */

/**
   * 特殊落地页定制
   * @value xinyonghuaRegisterPage -- 落地页加上贷款资金由正规金融持牌放款机构提供字段
   */

/**
   * 特殊功能定制
   * @value noIdentityCard -- 表单填写页面不输入身份证，而是输入年龄
   */

/**
   * 特殊落地页定制
   * @value noCompanyName -- 落地页不展示公司主体名称
   */

/**
   * 特殊落地页定制
   * @value kxzn -- 落地页底部利率修改
   */

/**
   * 特殊落地页定制
   * @value kxzn -- 添加
   */

let specialChannelCode = {

  /**
  * 特殊渠道定制
  * @value hbjf
  * @Infomation 享呗金服v0.1.40 - 特殊渠道定制
  * @intruduce FG9fGQ是正式渠道，7VU2Ln是测试渠道
  * @intruduce 内容与麦吉优渠道一样，在麦吉优的渠道基础上增加了部分新增处理，如下：
  * @intruduce 去掉页面title的有钱钱包
  * @intruduce 最高可借200000元”处，需要增加一条说明“具体金额以审批结果为准
  * @intruduce “借1000元用1天0.2元起”改为“年化利率7.2%起”
  * @intruduce 页面底部的“7%-24%”改为“7.2%-24%”
  * @link 产品文档:https://chongdong.feishu.cn/docx/N4DwdwDWAowligxu25BcmZCTnjh
  */
  hbjfCode: {
    value: 'hbjf',
    code: ['FG9fGQ', '7VU2Ln']
  },

  /**
   * 特殊渠道定制
   * @value mjy
   * @channel PD4Amz是正式渠道，25WbiH是测试渠道
   * @Infomation 麦吉优渠道v0.1.19 - 特殊渠道定制
   * @intruduce 所有页面不展示公司名称、产品名称和 ICP 备案信息
   * @intruduce 所有涉及到虫洞跳跃公司信息的，改为【本公司】
   * @intruduce header改为极速借贷
   * @intruduce 注册页和结果页不展示公司名称和logo
   * @intruduce 授权协议由于修改内容较多，直接替换原本协议
   * @link 产品文档:https://chongdong.feishu.cn/docx/IWNgdwfmwovgMQxRIuMcKDdpnXZ
   */
  ismjyCode: {
    value: 'mjy',
    code: ['PD4Amz', '25WbiH']
  },

  /**
   * 特殊渠道定制
   * @value tengxun
   * @channel '01bpDe'是腾讯正式渠道，0Men6r是腾讯测试渠道，
   * @Infomation 腾讯渠道 - 特殊渠道定制
   * @append 追加需求：需要更换腾讯渠道文案主体和icp备案信息。
   * @append 追加需求: 不匹配直接跳转结果页展示 -noMate
   * @link 产品文档:https://chongdong.feishu.cn/docx/Czz1dM0vro1OfCxzTR0cigkgngr
   */
  tengxunCode: {
    value: 'tengxun-noMate-baseSuccessPage',
    code: ['0Men6r', '01bpDe']
  },

  /**
    * 特殊渠道定制
    * @value kuaishou
    * @channel CG5Gd7是测试渠道,其余是正式渠道
    * @Infomation 快手渠道v0.1.26 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 jufu
    * @intruduce 将全局的主体更换为广州市聚富互联网小额贷款有限公司 jfxd
    * @intruduce 授权页面只展示产品名称 -justShowProduct
    * @intruduce 修改全局的名称和logo为聚融易 -jurongyi
    * @intruduce 快手埋点 -ksBurying
    * @append 追加需求：将快手渠道加入到聚客融皮肤中。
    * @link 产品文档:https://chongdong.feishu.cn/docx/InObd3Ue5oR3OSxdjxPcrKYbncg
    */
  // kuaishouCode: {
  //   value: 'jufu-kuaishou-justShowProduct-jfxd-jurongyi-ksBurying-cancleFillBtn',
  //   code: ['EQrvFe', 'rYoXK7', 'gzpQGV', 'CG5Gd7', 'e9oWWf']
  // },

  /**
    * 特殊渠道定制
    * @value jufu-jurongyi
    * @channel 57KGcK说测试渠道
    * @intruduce 聚客融的360借条 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 jufu
    * @intruduce 将logo和名称改为聚融易 jurongyi
    * @intruduce 将全局的主体更换为广州市聚富互联网小额贷款有限公司 jfxd
    * @link 产品文档:https://chongdong.feishu.cn/docx/InObd3Ue5oR3OSxdjxPcrKYbncg
    */
  jufuJurongyiCode: {
    value: 'jufu-jurongyi-jfxd',
    code: ['99pH5k', 'aQAYwm', 'UJ2lgl', '7pPkYx', 'EWpHOH', '57KGcK', 'vdC4PZ']
  },

  /**
    * 特殊渠道定制
    * @value jufu-juhaojie-backRegister-shenzhenJkr
    * @Infomation rqAs0l是测试渠道也是正式渠道之一
    * @Infomation 分期乐-聚客融 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 -jufu
    * @intruduce 修改全局的名称为聚好借 -juhaojie
    * @intruduce 海尔消金渠道需要返回按键直接返回到登录页，并清除掉状态 -backRegister
    * @intruduce 修改全局的主体为深圳聚客融科技有限公司 shenzhenJkr
    */
  jufufenqileCode: {
    value: 'jufu-juhaojie-backRegister-shenzhenJkr',
    code: ['rqAs0l', '3G4cvZ']
  },

  /**
    * 特殊渠道定制
    * @value jufu-juhaojie-backRegister-shenzhenJkr-jkrHaierDownloadApp
    * @Infomation SKoO0k是测试渠道
    * @Infomation 海尔消金-聚客融 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 -jufu
    * @intruduce 修改全局的名称为聚好借 -juhaojie
    * @intruduce 海尔消金渠道需要返回按键直接返回到登录页，并清除掉状态 -backRegister
    * @intruduce 修改全局的主体为深圳聚客融科技有限公司 shenzhenJkr
    * @intruduce 聚客融海尔下载自己的app jkrHaierDownloadApp
    */
  jufuhaierCode: {
    value: 'jufu-juhaojie-backRegister-shenzhenJkr-jkrHaierDownloadApp',
    code: ['mvLiPN', 'AOSMR4', 'SKoO0k']
  },

  /**
    * 特殊渠道定制
    * @value jufu-juhaojie-shenzhenJkr
    * @channel TXtKKt是测试渠道
    * @Infomation 聚客融-聚好借 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 -jufu
    * @intruduce 修改全局的名称为聚好借 -juhaojie
    * @intruduce 修改全局的主体为深圳聚客融科技有限公司 -shenzhenJkr
    */

  jufujuhaojieCode: {
    value: 'jufu-juhaojie-shenzhenJkr',
    code: ['NtTVog', 'iKNKOz', 'KRHLL6', 'BryFpJ', '82VZSf', 'NbBvJh', 'uy9QYW', 'bMsY0F', 'xAgatV']
  },

  /**
    * 特殊渠道定制
    * @value jiezhanggui
    * @channel 7SWRtn是测试渠道
    * @Infomation 借掌柜-有钱钱包 - 特殊渠道定制
    * @intruduce 修改注册页和授权页协议主体的名称为本平台
    */
  jiezhangguiCode: {
    value: 'jiezhanggui',
    code: ['P9mMYf', 'BXq2JR', '7SWRtn']
  },

  /**
    * 特殊渠道定制
    * @value jufu-juhaojie-shenzhenJkr-hbRegisterCustomized
    * @channel TXtKKt是测试渠道
    * @Infomation 聚客融-聚好借 - 特殊渠道定制
    * @intruduce 用jufu的皮肤 -jufu
    * @intruduce 修改全局的名称为聚好借 -juhaojie
    * @intruduce 修改全局的主体为深圳聚客融科技有限公司 -shenzhenJkr
    * @intruduce 还呗注册页面定制，标签和按钮内容定制 -hbRegisterCustomized
    */
  huanbeijufuCode: {
    value: 'jufu-juhaojie-shenzhenJkr-hbRegisterCustomized',
    code: ['EUbnkv', 'BmWW4U', 'esvcz0', 'OEvW9F', 'D7PeH9', 'OInR2n', 'EBUgIN', 'LwvhAl', 'TXtKKt']
  },

  /**
      * 特殊渠道定制
      * @Infomation 还呗渠道v0.1.19 - 特殊渠道定制
      * @intruduce FnbhpG是测试渠道,其余是正式渠道
      * @intruduce 更改主体和备案号
      * @intruduce 在做这个需求时将全局的有钱来更换为有钱钱包
      * @intruduce 匹配到一个或多个产品均不展示公司主体名
      * @intruduce 将全局的主体更换为深圳市中安信业小额贷款有限公司 zhonganXy
      * @link 产品文档:https://chongdong.feishu.cn/docx/HM3ydfbj2ozaZ2x4CNsc60bdnUh
      */
  huanbeiCode: {
    value: 'huanbei-zhonganXy-hbRegisterCustomized',
    code: ['FnbhpG', 'XPPL9v', 'hKvhDm', 'FLmCzN', '7wQBe8', 'SNTxDz', 'AHb0TS', 'QYnEeB', '1Lnjly', '9N9JE8', '8nXIby', 'FLmCZN']
  },

  /**
      * 特殊渠道定制
      * @Infomation 分期乐 -未开始版本号 - 特殊渠道定制
      * @intruduce 用分期乐定制的皮肤 -fenqile
      */
  fenqileCode: {
    value: 'fenqile',
    code: ['9o0w2B']
  },

  /**
      * 特殊渠道定制 - 年代比较久，没有产品文档
      * @Infomation 百度渠道-未开始版本号 - 特殊渠道定制
      * @intruduce 百度渠道访问时会有一个bd_vid，如果是百度渠道来的，到了授权页时需要通过授权接口将它传给后端，页面没有特定展示内容 -- bdVid
      * @intruduce mrCGvU,4eXawu,CowI4M,kU6KgW,sE02eN是之前的渠道，ObgOmw，oznJiG，J6XtJh是官方渠道，dU5COM，3tHcn0，vXvWji是后接渠道 -- bdVid
      * @append 追加需求：百度渠道单独定制落地页 -- baidu。
      * @append 列表不展示公司主体 -- noListMainBody
      * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=2511-1946&t=iEcT0VtX63JlIXt4-0
      * @link 产品文档:-
   */
  baiduCode: {
    value: 'baidu—bdVid-noListMainBody',
    code: ['mrCGvU', '4eXawu', 'CowI4M', 'kU6KgW', 'sE02eN', 'ObgOmw', 'oznJiG', 'J6XtJh', 'dU5COM', '3tHcn0', 'vXvWji', '5oNfJw']
  },

  /**
      * 特殊渠道定制
      * @Infomation 海尔消金 - 特殊渠道定制
      * @intruduce 渠道需要返回按键直接返回到登录页，并清除掉状态 --backRegister
      * @link 落地页figma文档:-
      * @link 产品文档:-
   */
  haierCode: {
    value: 'backRegister-iosDownloadyqqb-androidDownloadyqqb',
    code: ['r97gwM', 'dhT0rS']
  },

  /**
      * 特殊渠道定制
      * @Infomation 拍拍贷 - 特殊渠道定制
      * @intruduce 拍拍贷结果页需要判断ios和小米系统，如果为ios则直接跳转到appstore，小米手机跳转小米应用市场
      * @intruduce 5XR0vc是测试渠道，其余是正式渠道
      * @link 落地页figma文档:-
      * @link 产品文档:
   */
  paipaidaiCode: {
    value: 'paipaidai-iosDownloadyqqb',
    code: ['rWMspl', 'ZgvvGJ', 'pmeLeA', 't4xtSe', '5XR0vc']
  },

  /**
      * 特殊渠道定制
      * @Infomation 拍拍贷 - 特殊渠道定制
      * @intruduce 拍拍贷结果页需要判断ios和小米系统，如果为ios则直接跳转到appstore，小米手机跳转小米应用市场
      * @intruduce 5XR0vc是测试渠道，其余是正式渠道
      * @link 落地页figma文档:-
      * @link 产品文档:
   */

  /**
      * 特殊渠道定制
      * @Infomation 及锋 - 特殊渠道定制
      * @intruduce 底部添加贷款有风险 借款需谨慎文案 --registerTips
      * @link 落地页figma文档:- https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=4930-461&node-type=canvas&t=n2Ry8Owpw8jS0E2n-0
      * @link 产品文档:
   */
  jifengCode: {
    value: 'registerTips',
    code: ['oGutmt', 'Pn2EZp', '5Bho0Y', 'MKYJYz', 'FTqRo4', '9VbjZS', 'ldsRwI', 'xd4Zus', '2TatbE', 'S775BG', 'a5blxF']
  },

  /**
      * 特殊渠道定制
      * @Infomation 哈罗 - 特殊渠道定制
      * @intruduce 将全局的主体更换为广州市聚富互联网小额贷款有限公司 jfxd
      * @link 落地页figma文档:- https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5063-274&node-type=canvas&t=3Ckea9ublruN1won-0
      * @link 产品文档:
   */
  haluoCode: {
    value: 'zhonganXy',
    code: ['3HVc78']
  },

  /**
      * 特殊渠道定制
      * @Infomation 信用花 - 特殊渠道定制
      * @intruduce  落地页加上贷款资金由正规金融持牌放款机构提供字段 xinyonghuaRegisterPage
      * @link 落地页figma文档:
      * @link 产品文档:
   */
  xinyonghuaCode: {
    value: 'xinyonghuaRegisterPage',
    code: ['0Yi9gF']
  },

  /**
      * 特殊渠道定制
      * @Infomation 拍拍贷特殊渠道定制 - 特殊渠道定制
      * @intruduce  表单填写页面不填写身份证信息，改为填写年龄 --noIdentityCard
      * @link 落地页figma文档:
      * @link 产品文档:
   */
  paipaidai2Code: {
    value: 'noIdentityCard',
    code: ['vvLvWW']
  },

  /**
      * 特殊渠道定制
      * @Infomation 借掌柜特殊渠道定制 - 特殊渠道定制
      * @intruduce  落地页不展示公司主体名称 --noCompanyName
      * @link 落地页figma文档:
      * @link 产品文档:
   */
  jiezhanggui2Code: {
    value: 'noCompanyName',
    code: ['QndVP8']
  },

  /**
    * 特殊渠道定制
    * @Infomation 快手投放特殊表单流程页面配置 - 特殊渠道定制
    * @intruduce  表单流程3 --formprocess3
    * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5159-38824&node-type=frame&t=E7JkfAppc4Y1gvvF-0
    * @link 产品文档:
  */
  kuaishou2Code: {
    value: 'formprocess3-jurongyi-jfxd-jufu-ksBurying',
    // code: ['tX34rL', 'gzpQGV', 'rYoXK7', 'EQrvFe', 'S5aMBN', 'hUK4ux', 'yhBEjD', 'uCqaom', 'Iry9wH', 'TmMlva', '1biuPh', 'Ng1Iqq', 'd372Yw']
    code: ['tX34rL', 'gzpQGV', 'rYoXK7', 'EQrvFe', 'hUK4ux', 'S5aMBN', 'uCqaom', 'Iry9wH', '1biuPh', 'Ng1Iqq'],
  },

  /**
    * 特殊渠道定制
    * @Infomation  - 特殊渠道定制
    * @intruduce  表单流程3 --formprocess3
    * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5159-38824&node-type=frame&t=E7JkfAppc4Y1gvvF-0
    * @link 产品文档:
  */
  yqqbFormProcess: {
    value: 'formprocess3-yqqbKuaishou',
    code: ['tHH56Z', 'BaQAyf', 'PDGJx6', 'MvALAB', 'AJ6qxZ']
  },

  /**
    * 特殊渠道定制
    * @Infomation 快手投放特殊表单流程页面配置 - 特殊渠道定制
    * @intruduce  科星智能落地页修改修改地步利率 --xkzn
    * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5484-7777&t=L2u8kRlls0yH56LX-1
    * @link 产品文档:
  */
  xkzn1Code: {
    value: 'xkzn',
    code: ['E6N7eu']
  },

  /**
    * 特殊渠道定制
    * @Infomation 快手投放特殊表单流程页面配置 - 特殊渠道定制
    * @intruduce  科星智能落地页修改修改地步利率 --xkzn
    * @link 落地页figma文档:https://www.figma.com/design/QL5Bzxwqwscr1iDhvgO5r2/%E6%9C%89%E9%92%B1%E6%9D%A5-v0.1?node-id=5484-7777&t=L2u8kRlls0yH56LX-1
    * @link 产品文档:
  */
  xkzn2Code: {
    value: 'xkzn-registerTips',
    code: ['zE4xRb']
  },

}

const initialState: adChannelState = {
  adChannelCode: '',
  isSpecialChannel: false,
  specialCode: specialChannelCode,
  channelValue: [],
}

const getSpecialCode = (code: string, specialCodeMap: Record<string, { value: string, code: string[] }>): string | null => {
  for (let key in specialCodeMap) {
    if (specialCodeMap[key].code.includes(code)) {
      return specialCodeMap[key].value
    }
  }
  return null
}

export const checkSpecialChannel = createAsyncThunk(
  'channel/checkSpecialChannel',
  (_, { getState }) => {
    const state = getState() as { channel: adChannelState }
    const { adChannelCode, specialCode } = state.channel
    const specialValue = getSpecialCode(adChannelCode, specialCode)?.split('-')
    console.log(specialValue)

    return specialValue
  }
)

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setAdChannelCode(state, action) {
      state.adChannelCode = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(checkSpecialChannel.fulfilled, (state, action) => {
      state.isSpecialChannel = !!action.payload
      if (action.payload) {
        state.channelValue = action.payload
      }
    })
  }
})

export const { setAdChannelCode } = channelSlice.actions
export default channelSlice.reducer
