/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import Header from '@/components/header/header'
import styles from './jkr-student-agreement.module.scss'
import classNames from 'classnames'
import { getParams, formatTime } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import indexApi from '@/apis/index'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { encryptString } from '@/utils/utils'

interface Props{ }

export interface LoginInfo {
  uid: string,
  realName: string,
  mobile: string,
  loginAt:string
}
// 聚客融非大学生承诺函
const JukerongStudentAgreement:React.FC<Props> = props => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const [isShowHeader, setIsShowHeader] = useState(false)
  const [loginInfo, setLoginInfo] = useState<LoginInfo | any>()

  // 获取用户信息
  const getLoginInfo = async() => {
    let fillInformation = localStorage.getItem('fillInformation')
    let useInfo = fillInformation && JSON.parse(fillInformation)
    if (useInfo) {
      setLoginInfo(useInfo)
    } else {
      const res: any = await indexApi.getApplyStatus()
      setLoginInfo(res.data)
    }
  }

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
    getLoginInfo()
  }, [])

  const signature = useMemo(() => {
    switch (true) {
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      default:
        return '聚好借'
    }
  }, [channelValue])

  const youqianlaiRegister = () => {
    return (
      <>
        <p>
        为客观、准确地评估本人的贷款资格及信用等级，现本人特此作出如下声明与承诺:
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>本人提交授信申请及贷款申请时，已知晓金融机构不向在校学生提供信贷服务。本人确认本人不是在校学生，不存在隐瞒或者虚构身份申请贷款的情形，且本人拥有合法的固定收入来源。</p>
        <div className={styles.emptyLine}></div>
        <p >
        本人承诺：以上为本人真实陈述,不存在故意隐瞒或者虚假陈述，若因本人故意隐瞒或者虚假陈述造成的一切法律后果由本人承担，由此给{signature}及贷款人造成损失的，本人愿意承担赔偿责任。
        </p>
        <div className={styles.emptyLine}></div>
        <p >
        本人同意：本人已完全理解并同意上述承内容，且执行并同意本承诺函以数字电文形式存储于{signature}系统，并一经本人点击确认或勾选同意后以电子签名的方式签署，立即生效。
        </p>
        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>
        特此确认。
        </p>
        <div className={styles.emptyLine}></div>
        {
          loginInfo && <>
            <p className={styles.bold}>承诺人:  {loginInfo?.realName}</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>证件号:  {encryptString(loginInfo?.identity, 4, 4) }</p>
            <div className={styles.emptyLine}></div>
            <p className={styles.bold}>日期:  {formatTime(Date.now(), 'YYYY-MM-DD hh:mm:ss')}</p>
          </>
        }
      </>
    )
  }


  return (
    <div className={classNames(styles.registerAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="非大学生承诺函" />
      }
      {
        youqianlaiRegister()
      }
    </div>
  )
}

export default JukerongStudentAgreement