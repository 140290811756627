import React, { useState, useImperativeHandle } from 'react'
import styles from './protocolIds-confirm-2.module.scss'
import { Button } from 'antd-mobile'
import { ENTER_AGREEMENT } from '@/constants/storage'
import { Storage } from '@bihu/common-js'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'
// interface Props{
// }
// 协议确认
export interface Props {
  init?: (obj: any | undefined) => void,
  onSuccess: (obj: any | undefined) => void
  openProtocolsPopup: () => void
  submit: () => void
  rqResult?:any
}
const Success = React.forwardRef(({ init, onSuccess, openProtocolsPopup, submit, rqResult }: Props, ref: React.ForwardedRef<Props>) => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [isShow, setIsShow] = useState(false)
  const userInfo = useAppSelector(selectUserInfo)

  // 有钱来个人信息授权书
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [otherProtocols, setotherProtocols] = useState([])

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)
  const [productId, setProductId] = useState<any>('')

  init = (obj: any | undefined) => {
    setBtnLoading(false)
    if (obj) {
      setYouqianlaiProtocol(obj.youqianlaiProtocol)
      setotherProtocols(obj.otherProtocols)
      setIsShow(true)
      setProductId(obj.productId)
    }
  }

  const close = () => {
    setIsShow(false)
  }

  const checkProtocolsPopup = () => {
    openProtocolsPopup()
  }

  const submitApplication = () => {
    setBtnLoading(true)
    close()
    submit()
  }
  // 暴露内部方法
  useImperativeHandle(ref, () => ({
    init,
    onSuccess,
    openProtocolsPopup,
    submit
  }))

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = url
  }

  return (
    <>
      {
        isShow && <section className={styles.modalPage}>
          <div className={styles.main}>
            <div className={styles.modalTitle}>温馨提示</div>
            <div className={styles.modalText1}>我已阅读并同意</div>
            <div className={styles.protocolIds}>
              {
                rqResult?.thirdpartyType !== 1 && channelValue?.includes('jufu') ? <>
                  <div className={styles.protocolIdsItem} onClick={() => {
                    openOtherView(`/jkr-general-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}&company=${rqResult?.productCompany || rqResult?.yqlWholeProcessBumpVO?.partnerCompanyName}`)
                  }}>《通用授权协议》</div>
                  <div className={styles.protocolIdsItem} onClick={() => {
                    openOtherView(`/jkr-student-agreement?isShowHeader=true&useInfo=${JSON.stringify(userInfo)}`)
                  }}>《非大学生承诺函》</div>
                </> : <div className={styles.protocolIdsItem} onClick={() => {
                  openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${productId}&protocolType=${channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? 1 : 0}`)
                }}>《{youqianlaiProtocol.name}》</div>
              }

              {
                otherProtocols.length > 0 && <div className={styles.protocolIdsItem} onClick={() => checkProtocolsPopup()}>《机构相关协议》</div>
              }
            </div>
            <div className={styles.btnsBox}>
              <Button block className={styles.cancelBtn} shape="rounded" color="primary" onClick={() => close()}>
                取消
              </Button>
              <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication()}>
                同意并继续
              </Button>
            </div>
          </div>
        </section>
      }
    </>
  )
})

export default Success