import { setTitle } from '@bihu/common-js'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import type { RootState } from '@/store'

export const useTitleAndFavicon = () => {
  const { adChannelCode, channelValue } = useSelector((state: RootState) => state.channel)
  const prevChannelValue = useRef<string[] | null>([])

  // 修改Favicon图标
  const changeIcoFavicons = (url: string) => {
    const favicons = document.querySelectorAll('link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]')
    favicons.length > 0 && favicons.forEach(link => link.parentNode!.removeChild(link))

    const favicon = document.createElement('link')
    favicon.rel = 'icon'
    favicon.type = 'image/x-icon'
    favicon.href = new URL(url, window.location.origin).toString()
    document.head.appendChild(favicon)

    const appleIcon = document.createElement('link')
    appleIcon.rel = 'apple-touch-icon'
    appleIcon.type = 'image/x-icon'
    appleIcon.href = new URL(url, window.location.origin).toString()
    document.head.appendChild(appleIcon)
  }

  useEffect(() => {
    if (prevChannelValue.current !== channelValue) {
      prevChannelValue.current = channelValue
      if (channelValue) {
        switch (true) {
          case channelValue.includes('hbjf'):
            setTitle('\u200B')
            return
          case channelValue.includes('jurongyi'):
            setTitle('聚融易')
            changeIcoFavicons('jurongyi-favicon.ico')
            return
          case channelValue.includes('juhaojie'):
            setTitle('聚好借')
            changeIcoFavicons('jufu-favicon.ico')
            return
          case channelValue.includes('jufu'):
            setTitle('聚客融')
            changeIcoFavicons('jufu-favicon.ico')
            return
          case channelValue.includes('baidu'):
            setTitle('\u200B')
            changeIcoFavicons('data:image/gifbase64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=')
            return
          default:
            setTitle('有钱钱包')
            changeIcoFavicons('youqianqianbao-favicon.ico')
        }
      } else {

        setTitle('有钱钱包')
        changeIcoFavicons('youqianqianbao-favicon.ico')
      }
    }
  }, [channelValue])
}