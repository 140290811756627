/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import Header from '@/components/header/header'
import styles from './privacy-agreement.module.scss'
import classNames from 'classnames'
import { getParams } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'

interface Props{}
// 隐私协议
const PrivacyAgreement:React.FC<Props> = () => {
  const [isShowHeader, setIsShowHeader] = useState(false)
  const { channelValue } = useSelector((state: RootState) => state.channel)

  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
  }, [])

  const getAgreementCompany = () => {
    switch (true) {
      case channelValue?.includes('zhonganXy'):
        return CompanyType.ZAXY_COMPANY.company
      case channelValue?.includes('jfxd'):
        return CompanyType.JFXD_COMPANY.company
      case channelValue?.includes('jiezhanggui'):
        return '本平台'
      default:
        return CompanyType.YQQB_COMPANY.company
    }
  }

  // 有钱钱包隐私协议
  const youqianlaiPrivacy = () => {
    return (
      <>
        <p>
          特别提示：有钱钱包（以下简称“我们”）在此特别提醒您（用户），请认真阅读本《隐私政策》（以下简称“本政策”），确保您充分理解本政策中各条款。当您同意我们的产品或服务，或您访问、下载我们的平台（包括但不限于网站、移动应用、短信平台、社交媒体、H5及小程序等），或您实际使用我们提供的任一产品或服务时，请您确认您已阅读并理解本政策条款。
        </p>
        <p className={styles.bold}>
          前言：有钱钱包是{ getAgreementCompany() }{channelValue?.includes('jiezhanggui') ? '' : '旗下'}产品。我们专注于为小微企业主、个体工商户及工薪阶层提供快速、简便的小额信贷技术服务，我们将与我们的各类合作方助力于解决他们融资难的社会问题。
        </p>
        <p>
          有钱钱包所有的产品和服务均适用本政策。有钱钱包不为在校学生提供借款服务，如您是在校学生，请您立即停止使用我们的借贷服务。某些特定产品和/或服务还将适用特定的隐私政策，我们将在向您提供这些特定产品和/或服务前说明，如与本政策有不一致之处，以特定产品和/或服务的隐私政策为准。除非本政策另有明确所指，本隐私政策不适用于通过我们的产品和/或服务而接入的由第三方独立为您提供的产品和/或服务的情形，我们在网站、有钱钱包借款APP、有钱钱包选贷小程序中向您显示的产品或网站，可能包含链接到的其他第三方网站、H5的功能。请注意，如果您通过这些链接进入或使用第三方的产品和／或服务时，向其提供了您的信息，您的信息应当适用该第三方的隐私声明或类似政策，同时我们建议您谨慎了解该第三方的隐私保护政策。
        </p>
        <p>
          我们的隐私政策（以下简称“本政策”）说明了我们收集、处理（本政策提及的“处理”，包括我们对您个人信息的核实、使用、共享、存储及保护等行为）您的个人信息的规则、目的、方式和范围，您的权利保障机制，以及我们保护您个人信息的措施等。我们对收集、处理您的个人信息负责。本政策适用于您通过我们提供的所有产品和服务。我们建议您完整、详细地阅读本政策，以帮助您可以按照本隐私政策作出您认为适当的选择。您同意本政策仅代表您已了解应用提供的功能，并同意我们按照本政策所述处理您的必须个人信息，并不代表您已同意我们可以收集非必须个人信息，非必须个人信息会根据您使用本应用功能的情况单独征求您的同意。
        </p>
        <p>
          我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、公开透明原则、确保安全原则、主体参与原则等。同时，我们承诺，我们将采取相应的安全保护措施来保护您的个人信息。本APP主要由{ getAgreementCompany() }负责技术开发与日常技术运营，我们有专门的团队保护您的公民个人信息，如有相关问题您可以通过以下方式联系我们。请注意，请您不要在服务中其他用户可见的公开区域内上传或发布您的信息，或对其他用户上传或发布的信息作出的回应中公开您的个人信息，该等信息可能会被其他用户收集并加以使用。当您发现用户不正当地收集或使用您或其他用户的信息时，请联系我们。
        </p>
        {channelValue?.includes('jiezhanggui') ? null
          : <p>
            <p className={styles.bold}>
          公司名称：{ getAgreementCompany() }
            </p>
            <p>个人信息保护负责人的邮箱：bd@chongdong.biz</p>
          </p>
        }
        <p>
          本政策依据截至生效日适用的法律、法规制定，同时也参考了适用的国家及行业标准、国际协议及惯例。如国家法律法规对本政策内容有另行规定或您与我们另行约定的条款与本政策不一致的，以另行规定、约定为准。本政策包含以下内容：
        </p>
        <p>一、我们的承诺</p>
        <p>二、我们收集个人信息的目的及范围</p>
        <p>三、我们如何收集个人信息</p>
        <p>四、我们如何存储个人信息</p>
        <p>五、我们如何共享、公开披露个人信息</p>
        <p>六、我们如何保护个人信息</p>
        <p>七、我们如何使用COOKIES等自动收集工具和第三方插件</p>
        <p>八、您的权利保障机制</p>
        <p>九、免责声明（非常重要）</p>
        <p>十、隐私政策的更新及生效</p>
        <p>十一、未成年人个人信息特别约定</p>
        <p>十二、您的申诉渠道和反馈机制</p>
        <p>十三、争议解决</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>一、我们的承诺</p>
        <p className={styles.bold}>1、我们仅在必要的、合法、合理的范围内收集、处理您的个人信息</p>
        <p className={styles.bold}>
          我们承诺我们在遵守国家法律法规规定的前提下收集、处理您的个人信息，仅在必要、合法、合理的范围内收集、处理您的个人信息。我们收集、处理您的个人信息是为了向您提供更优质的产品或服务（包括开发新产品或服务，或完善已有产品或服务功能。）
        </p>
        <p className={styles.bold}>2、我们仅在您知悉并同意的前提下收集、处理您的个人信息</p>
        <p className={styles.bold}>
          我们承诺会公开明确地告知您我们收集、处理您个人信息的范围、原因及方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。
        </p>
        <p className={styles.bold}>3、我们尊重您对个人信息的管理</p>
        <p className={styles.bold}>
          我们承诺会公开明确地告知您我们收集、处理您个人信息的范围、原因及方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。
        </p>
        <p className={styles.bold}>4、我们将采取必要的措施保证您的个人信息安全</p>
        <p className={styles.bold}>
          我们承诺在能力范围内采取必要的安全措施，以保障您的个人信息安全，包括但不限于通过符合适用的法律法规及标准要求的硬件、软件、数据、人员、物理环境及其基础设施防护措施保护您的个人信息安全；不仅如此，我们还会要求相关员工进行保密并通过对员工进行安全意识培训，敦促其遵守本政策。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>二、我们收集、使用个人信息的目的及范围</p>
        <p>
          本政策所指的“个人信息”是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。当您访问、下载我们的平台时，或您使用我们的产品或服务时，您会向我们主动提供您的个人信息，我们也会通过自动收集工具、第三方收集您的信息。我们收集、使用个人信息的目的及范围如下：
        </p>
        <p>
          1、确定您的资信状况当您使用或开启相关功能或接受服务时，为确保功能、服务得以实施，我们只会收集必要个人信息。除非完全必要，否则大部分情况下您无需提供非必要个人信息。除非经过您的允许，否则应用不会开启非必要权限。即便开启了经过您授权的非必要权限，您也可以随时在本应用或设备操作系统中停止授权。为了帮助我们确认您的资信状况或用于可能的争议解决，我们基于用户填写的表单将收集并使用如下必要信息和非必要信息：
        </p>
        <p>
         （1）必要个人信息：当您开通借贷服务时，我们将收集您的真实姓名、身份证信息、手机号、并通过平台进行信息验证
        </p>
        <p>
         （2）申请评估借款额度时，需要进一步提供您的个人信息用以额度授信。您需要提供以下非必要信息：您的地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在本应用平台留下的注册信息（电话，电子邮箱）、您的IP地址、设备品牌、设备型号与我们的服务相关的日志信息。
        </p>
        <p>（3）调取的敏感权限</p>
        <p>
          我们收集上述信息，可能将调用您的相机权限、相册权限、地理位置权限。如您拒绝系统调用相关权限，我们将无法收集上述相应信息，可能导致系统无法正常提供服务。
        </p>
        <p>
          2、我们还可能为以下需要而保留、保存或披露您的个人信息：
        </p>
        <p>1）遵守适用的法律法规。</p>
        <p>2）遵守法院命令或其他法律程序的规定；</p>
        <p>3） 遵守相关政府机关的要求；</p>
        <p>4）为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们、其他用户的人身和财产安全或合法权益所合理必需的用途。</p>
        <p>3、为您提供借款、咨询及推荐服务</p>
        <p>为更优质和高效地向您提供我们的借款、咨询及推荐服务，我们将收集并使用您的下列信息：</p>
        <p>1）上述用于确定您的资信状况的信息；</p>
        <p>2）上述用于及时联系您以便处理您的投诉或提醒您履行合同义务；</p>
        <p>4、为您提高用户体验、个性化推荐、发送促销营销信息。</p>
        <p>
          为了向您发送服务状态通知、展现或推荐相关程度更高的产品、信息流或广告、推广信息及优惠信息或完善我们的营销体系（例如确保您需要的营销信息更加精准、便捷、高效地到达），我们将收集并使用您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。
        </p>
        <p>
          5、监测我们平台的性能和稳定性，优化性能、支持、改进服务质量。我们可能通过COOKIES等自动收集工具收集信息，包括您的IP地址、设备型号、设备品牌、操作系统版本、及我们的服务相关的日志信息。
        </p>
        <p>
          6、完善我们的内部风险控制为了帮助我们完善风控体系，自行或通过委托第三方建立数据模型或者建立性格测试模型，用于特征分析和用户画像，帮助我们进行内部风控，或我们通过技术手段对您的个人信息进行去标识化或匿名化处理的情况下，对该等数据的挖掘、分析/利用（包括商业化利用）和与第三方共享，我们将收集并使用您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。
        </p>
        <p>
          7、其他您个人信息的潜在用途
        </p>
        <p>
          1） 预防或禁止非法的活动
        </p>
        <p>2） 遵守法律法规的义务</p>
        <p>3） 经您许可授权的其他用途</p>
        <p>当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</p>
        <p>当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
        <p>为实现应用消息推送功能，及跨应用打开有钱钱包并完成页面还原功能，我们会共享必要信息给服务提供商</p>
        <table border={1} cellSpacing={0} cellPadding={4}>
          <tbody>
            <tr>
              <td className={styles.width80}>SDK 名称</td>
              <td>SDK 收集信息说明</td>
              <td className={styles.width80}>服务提供商</td>
            </tr>
            <tr>
              <td>华为推送SDK</td>
              <td>应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</td>
              <td>华为软件技术有限公司</td>
            </tr>
            <tr>
              <td>推送服务（OPPO PUSH）</td>
              <td>为向您提供推送服务，发送关于软件更新或新品发布等通知（包括但不限于我们及相关第三方产品或服务的营销信息），您同意我们暂存从开发者处获取的消息内容，并收集您的设备相关信息（如IMEI或OAID，Serial Number，IMSI，User ID，Android ID，Google Advertising ID, 手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（如APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（如IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（如通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）。</td>
              <td>广东欢太科技有限公司</td>
            </tr>
            <tr>
              <td>小米消息推送服务</td>
              <td>小米推送（MiPush）是小米公司向开发者提供的消息推送服务，通过在云端与客户端之间建立一条稳定、可靠的长连接，为开发者提供向客户端应用实时推送消息的服务，有效地帮助开发者触达用户，提升App活跃度。
设备标识(OAID、加密的Android ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置</td>
              <td>北京小米移动软件有限公司</td>
            </tr>
            <tr>
              <td>vivo推送（即Vpush）</td>
              <td>vivo推送（即Vpush）是vivo公司向开发者提供的消息推送服务，通过在云端与客户端之间建立一条稳定、可靠的长连接，为开发者提供向客户端应用实时推送消息的服务，支持百亿级的通知/消息推送，秒级触达移动用户。
为将应用开发者提供的相关信息推送到您的设备终端，我们会收集您的设备标识信息（如IMEI、EmmCID、UFSID、ANDROIDID、GUID、GAID、OPENID、VAID、OAID、RegID、加密的Android ID）、使用推送服务的应用软件信息（如应用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络类型、国家码、设备类型；</td>
              <td>维沃移动通信有限公司及其关联公司</td>
            </tr>
            <tr>
              <td>友盟消息推送服务</td>
              <td>产品集成友盟+SDK及推送通道SDK（如小米、华为、oppo、vivo、魅族等），推送通道SDK需要收集采集设备标识符（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM 卡 IMSI 信息等），用于唯一标识设备，以便向用户设备推送消息。采集地理位置甄别推送通道，提高消息推送的区域覆盖率</td>
              <td>友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>三、我们如何收集个人信息</p>
        <p>1、您直接提供给我们的信息</p>
        <p>
          您注册我们的会员账户、申请或使用我们的产品或服务，或通过电子邮件、电话或其他方式联系我们时，可能向我们提供一些个人信息。若您不提供这些信息或提供的信息有误，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p>2、我们主动收集的信息</p>
        <p>
          除了您提供给我们的个人信息外，我们可能直接通过COOKIES等自动收集工具收集您的信息，如您的IP地址、设备型号、设备品牌、操作系统版本、位置以及与我们的服务相关的日志信息。若您拒绝我们收集此类信息，我们将无法全部或部分为您提供相关产品或服务的功能，但不会影响我们向您提供核心的产品或服务的除外。
        </p>
        <p>3、设备权限调用</p>
        <p>
          在提供服务过程中，为了保证您能正常使用产品功能，我们会向系统申请您的以下设备权限，申请前我们会征询您的同意，您可以选择“允许”或“禁止”权限申请；申请成功后，您可以随时进入手机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用，请您理解：
        </p>
        <p>
          1）为了给您提供身份验证、语音搜索服务，我们会向系统申请使用“相机权限”和“麦克风权限”，请您留意；
        </p>
        <p>2）为了给您提供精准便捷的服务，我们会向系统申请“位置权限”；</p>
        <p>
         3）为了能正常读取设备识别号码，保证您能正常保存图片、文件或缓存信息到手机时使用，我们会向系统申请“读取手机状态、启用存储读取权限”。为了给您提供更优质的服务，我们可能会根据情况向系统申请您的其他设备权限，如果您拒绝开启相应设备权限，您可以随时进入手机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用，请您理解。
        </p>
        <p className={styles.bold}>4、来自有钱钱包合作伙伴的信息</p>
        <p className={styles.bold}>
          我们可能会从合作伙伴处获得您的信息，包括但不限于您在有钱钱包合作公司所形成的产品或服务的表单信息及注册信息，您在其他商业合作伙伴处的交易及注册信息（表单信息）。若您拒绝授权或同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p className={styles.bold}>5、来自其他第三方的信息</p>
        <p className={styles.bold}>
          我们可能会通过其他第三方对您提供的信息进行验证，或者通过您提供的信息在第三方渠道查询您的其他信息，您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。若您拒绝授权或不同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p>6、在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需经过您的授权同意：</p>
        <p>1） 与国家安全、国防安全有关的；</p>
        <p>2） 与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>3） 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的；</p>
        <p>5） 所收集的个人信息是您自行向社会公众公开的；</p>
        <p>6） 从合法公开披露的信息中收集到的个人信息，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>7） 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>8） 法律法规规定的其他情形。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>四、我们如何存储个人信息</p>
        <p>1、保存的地域</p>
        <p>您的个人信息储存于中华人民共和国境内，我们不会在境外存储您的个人信息。</p>
        <p>2、保存的期限</p>
        <p>除非法律法规另有规定，我们将按如下方式及期间保存您的信息：</p>
        <p>
          1）您使用我们产品或服务期间，我们将综合考虑为您提供产品或服务的必要性，根据业务需要持续为您保存或删除个人信息；
        </p>
        <p>
          2） 但是，即便有第1） 款之规定，在我们不再为您提供产品或服务且在相关技术设置允许条件下，您有权联系我们注销账户并删除您的个人信息；
        </p>
        <p>
          3） 进一步的，即便有第2） 款之规定，在某些特定情况下，有且仅为了遵守国家安全、网络安全等法律规定的法定义务或政府命令，我们仍将继续保存您的信息，直至法定义务免除。
        </p>
        <p>3、跨境转移</p>
        <p>除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>五、我们如何共享、公开披露个人信息</p>
        <p className={styles.bold}>1、我们不会与任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
        <p className={styles.bold}>1） 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
        <p className={styles.bold}>2） 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
        <p className={styles.bold}>
          3） 与合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
        </p>
        <p>我们的合作伙伴包括：</p>
        <p>
          A、技术服务类合作伙伴。基于您的授权及设定，收取您的位置信息及设备信息并提供给技术服务商，特殊情境下会包含您另行填写的个人信息，以获取他们的支持，这些支持包括但不限于提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们线上服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查与分析。
        </p>
        <p>
         B、广告、数据分析类合作伙伴。 未经您的授权，我们不会将您的个人信息与提供广告、分析服务的非合作伙伴共享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人身份信息的前提下提升广告及服务信息的精准度及有效触达率。
        </p>
        <p>2、我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1） 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>3、我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1） 获得您明确同意后</p>
        <p>
          2） 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>六、我们如何保护个人信息</p>
        <p className={styles.bold}>
          1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理合法可行的措施，保护您的个人信息，包括但不限于制定了严格的内部信息管理流程、对数据进行加密、定岗定责、控制访问权限、采取安全措施等，以防止其丢失、被非法使用、受到未授权访问或泄漏、被篡改或毁坏。
        </p>
        <p className={styles.bold}>
          2、我们会采取一切合理合法可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p className={styles.bold}>
          3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p className={styles.bold}>
          4、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p className={styles.bold}>
          5、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p className={styles.bold}>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>七、我们如何使用COOKIES等自动收集工具和第三方插件</p>
        <p>
          （1）当您使用安信花平台时，我们使用COOKIES和其他类似技术来记录信息，您的IP地址、设备品牌、设备型号、操作系统版本、我们的服务相关的日志信息。此等技术帮助我们更好地了解用户的行为，告诉我们您浏览了我们平台的哪些部分，以及您正在处理的交易，并衡量广告和网络搜索的效果并加以改善。
        </p>
        <p>
          请您理解，我们平台中的许多COOKIES起着至关重要的作用：例如，当您登录我们账号时，COOKIES记录着您的账号信息，使您省去重复输入注册信息等步骤，或帮助您判断您的账户安全。其它的COOKIES帮助我们了解您使用我们平台的方式，从而使我们改进线上工作。对此我们使用相关的分析工具分析我们平台的加载时间、访客使用的方式，以及我们平台的访客查看最多的信息。它们还帮助我们发现我们平台中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的平台。
        </p>
        <p>
          您可以选择接受或拒绝所有COOKIES，或拒绝具体类别的COOKIES。如果您关闭COOKIES后，您仍可以使用我们平台的部分内容，但有些有用部分将不能使用，并且，将可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>八、您的权利保障机制</p>
        <p className={styles.bold}>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
        <p className={styles.bold}>1、查询您的个人信息</p>
        <p className={styles.bold}>您有权查询您的个人信息，法律法规规定的例外情况除外。如果您想查询您的个人信息，可以通过以下方式自行查询：</p>
        <p className={styles.bold}>
          1） 账户信息：如果您希望查询或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问执行此类操作。
        </p>
        <p className={styles.bold}>
          2） 搜索信息——您可以在安信花的平台中访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。
        </p>
        <p className={styles.bold}>如果您无法通过上述链接访问这些个人信息，您可以随时与我们联系。我们将及时回复您的访问请求。</p>
        <p className={styles.bold}>
          对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请联系我们。
        </p>
        <p className={styles.bold}>2、更正您的个人信息</p>
        <p className={styles.bold}>当您发现我们处理的关于您的个人信息有错误时，您有权联系我们做出更正。</p>
        <p className={styles.bold}>3、删除您的个人信息</p>
        <p className={styles.bold}>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p className={styles.bold}>1） 如果我们处理个人信息的行为违反法律法规</p>
        <p className={styles.bold}>2） 如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p className={styles.bold}>3） 如果我们处理个人信息的行为违反了与您的约定</p>
        <p className={styles.bold}>4） 如果我们不再为您提供产品或服务</p>
        <p className={styles.bold}>
          若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p className={styles.bold}>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
        <p className={styles.bold}>4、撤销您的个人信息</p>
        <p className={styles.bold}>
          每个业务功能需要一些基本的个人信息才能得以完成，您无法撤销正在进行的业务功能对应的信息。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
        </p>
        <p className={styles.bold}>如果您不想接受我们给您发送的商业广告，您随时联系我们取消。</p>
        <p className={styles.bold}>以APP为例，撤销信息授权的途径为：拨打我们提供的客服电话将您的撤销申请提交给我们。</p>
        <p className={styles.bold}>5、用户账户注销</p>
        <p className={styles.bold}>
          如果您与我们的权利义务均已享受或履行完毕、您注册以后不再需要我们提供的服务，您可以联系我们的客服人员进行用户账户的注销。
        </p>
        <p className={styles.bold}>6、响应您的上述请求</p>
        <p className={styles.bold}>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p className={styles.bold}>在以下情形中，我们将无法响应您的请求：</p>
        <p className={styles.bold}>1） 与个人信息控制者履行法律法规规定的义务相关的；</p>
        <p className={styles.bold}>2） 与国家安全、国防安全直接相关的；</p>
        <p className={styles.bold}>3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p className={styles.bold}>4） 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p className={styles.bold}>5） 个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
        <p className={styles.bold}>6） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p className={styles.bold}>7） 响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
        <p className={styles.bold}>8） 涉及商业秘密的。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>九、免责声明（非常重要）</p>
        <p className={styles.bold}>
          互联网并非绝对安全的环境，可能存在或出现各种恶意或非恶意的攻击手段。您使用产品或服务时所用的系统和通讯网络，或硬件设备等我们均无法控制，请您了解并注意保护您的个人信息安全。虽然我们持续致力于提升和加强安全措施，以保护您的个人信息免遭意外或破坏，但仍无法始终保证您的个人信息的百分之百安全。
        </p>
        <p className={styles.bold}>
          请您注意，我们的网站、产品、应用软件和服务中可能含有第三方网站、产品或服务的链接。第三方收集的可能涉及您的个人信息，第三方须遵守关于个人信息的规定。我们希望你能了解这些第三方的隐私政策。我们会尽商业上的合理努力去要求这些第三方主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您不了解这些第三方创建的网页和开发的应用程序或发现他们存在风险时，建议您终止相关操作以保护您的合法权益。
        </p>
        <p className={styles.bold}>
          因信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。除非本政策有其他规定，为了保护您的人身及财产安全，我们特别提醒您不要通过公共网络，向我们或任何以我们名义为您提供服务的第三方，提供您的账户密码、验证码、通信内容等信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十、隐私政策的更新及生效</p>
        <p>
          本政策自发布之日起生效，如果发生业务功能变更、个人信息出境情况变更、个人信息使用目的变更、个人信息保护相关负责人联络方式变更等情形时，我们将对隐私政策进行相应的修订，届时我们会通过推送通知等方式告知您。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十一、未成年人个人信息特别约定</p>
        <p>
          我们非常重视未成年人的个人信息保护，我们的网站、应用及相关产品或服务均不以未成年人为目标客户。我们不会故意收集未成年人的任何个人信息，除非您主动提供且因获取某些产品或服务必须提供某些未成年人的必要信息外（例如您提供的房产信息可能包括未成年人信息）。可能导致系统无法正常提供服务。
        </p>
        <p>
          如果您是未成年人，请不要注册安信花平台，或不要以其它方式向我们提供任何您的个人信息。如果我们发现经疏忽获取了未成年人的个人信息，我们将尽快删除该等信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十二、您的申诉渠道和反馈机制</p>
        <p className={styles.bold}>
          如果您对本政策有任何疑问、意见或建议，或您在使用我们产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见、建议、投诉或举报，或您在使用产品或服务时遇到哪些问题，请通过邮箱： bd@chongdong.biz 联系我们，我们会在不超过15个工作日或法律法规要求的期限内同电话等方式及时答复您：
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十三、争议解决渠道</p>
        <p className={styles.bold}>
          当您以适当的方式开始、继续使用我们的产品或服务超过15日的，您未通过我们公布的联系方式（请您注意我们在平台公布的联系方式），就本政策的相关内容明示表示拒绝的，视为您充分理解并同意本政策。您理解并同意，在您明示拒绝前，我们依据本政策收集和使用的个人信息仍然有效。
        </p>
        <p className={styles.bold}>
          您和我们就本政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，您可以向有关行业协会（例如消费者协会等）、监管部门（网信办等）或权威部门投诉、举报，或者依据适用的法律向具有管辖权的法院提起诉讼。
        </p>
        <p className={styles.bold}>本协议生效时间：2023-03-01</p>
      </>
    )
  }

  // 麦吉优渠道隐私协议
  const mjyPrivacy = () => {
    return (
      <>
        <p>
        特别提示：本公司（以下简称“我们”）在此特别提醒您（用户），请认真阅读本《隐私政策》（以下简称“本政策”），确保您充分理解本政策中各条款。当您同意我们的产品或服务，或您访问、下载我们的平台（包括但不限于网站、移动应用、短信平台、社交媒体、H5及小程序等），或您实际使用我们提供的任一产品或服务时，请您确认您已阅读并理解本政策条款。
        </p>
        <p className={styles.bold}>
        前言：本公司专注于为小微企业主、个体工商户及工薪阶层提供快速、简便的小额信贷技术服务，我们将与我们的各类合作方助力于解决他们融资难的社会问题。
        </p>
        <p>
        本公司所有的产品和服务均适用本政策。本公司不为在校学生提供借款服务，如您是在校学生，请您立即停止使用我们的借贷服务。某些特定产品和/或服务还将适用特定的隐私政策，我们将在向您提供这些特定产品和/或服务前说明，如与本政策有不一致之处，以特定产品和/或服务的隐私政策为准。除非本政策另有明确所指，本隐私政策不适用于通过我们的产品和/或服务而接入的由第三方独立为您提供的产品和/或服务的情形，我们在网站、APP、小程序中向您显示的产品或网站，可能包含链接到的其他第三方网站、H5的功能。请注意，如果您通过这些链接进入或使用第三方的产品和／或服务时，向其提供了您的信息，您的信息应当适用该第三方的隐私声明或类似政策，同时我们建议您谨慎了解该第三方的隐私保护政策。
        </p>
        <p>
        我们的隐私政策（以下简称“本政策”）说明了我们收集、处理（本政策提及的“处理”，包括我们对您个人信息的核实、使用、共享、存储及保护等行为）您的个人信息的规则、目的、方式和范围，您的权利保障机制，以及我们保护您个人信息的措施等。我们对收集、处理您的个人信息负责。本政策适用于您通过我们提供的所有产品和服务。我们建议您完整、详细地阅读本政策，以帮助您可以按照本隐私政策作出您认为适当的选择。您同意本政策仅代表您已了解应用提供的功能，并同意我们按照本政策所述处理您的必须个人信息，并不代表您已同意我们可以收集非必须个人信息，非必须个人信息会根据您使用本应用功能的情况单独征求您的同意。
        </p>
        <p>
        我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、公开透明原则、确保安全原则、主体参与原则等。同时，我们承诺，我们将采取相应的安全保护措施来保护您的个人信息。本APP主要由本公司负责技术开发与日常技术运营，我们有专门的团队保护您的公民个人信息，如有相关问题您可以通过以下方式联系我们。请注意，请您不要在服务中其他用户可见的公开区域内上传或发布您的信息，或对其他用户上传或发布的信息作出的回应中公开您的个人信息，该等信息可能会被其他用户收集并加以使用。当您发现用户不正当地收集或使用您或其他用户的信息时，请联系我们。
        </p>
        <p>
        本政策依据截至生效日适用的法律、法规制定，同时也参考了适用的国家及行业标准、国际协议及惯例。如国家法律法规对本政策内容有另行规定或您与我们另行约定的条款与本政策不一致的，以另行规定、约定为准。本政策包含以下内容：
        </p>
        <p>一、我们的承诺</p>
        <p>二、我们收集个人信息的目的及范围</p>
        <p>三、我们如何收集个人信息</p>
        <p>四、我们如何存储个人信息</p>
        <p>五、我们如何共享、公开披露个人信息</p>
        <p>六、我们如何保护个人信息</p>
        <p>七、我们如何使用COOKIES等自动收集工具和第三方插件</p>
        <p>八、您的权利保障机制</p>
        <p>九、免责声明（非常重要）</p>
        <p>十、隐私政策的更新及生效</p>
        <p>十一、未成年人个人信息特别约定</p>
        <p>十二、您的申诉渠道和反馈机制</p>
        <p>十三、争议解决</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>一、我们的承诺</p>
        <p className={styles.bold}>1、我们仅在必要的、合法、合理的范围内收集、处理您的个人信息</p>
        <p className={styles.bold}>
          我我们承诺我们在遵守国家法律法规规定的前提下收集、处理您的个人信息，仅在必要、合法、合理的范围内收集、处理您的个人信息。我们收集、处理您的个人信息是为了向您提供更优质的产品或服务（包括开发新产品或服务，或完善已有产品或服务功能。
        </p>
        <p className={styles.bold}>2、我们仅在您知悉并同意的前提下收集、处理您的个人信息</p>
        <p className={styles.bold}>
        我们承诺会公开明确地告知您我们收集、处理您个人信息的范围、原因及方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。
        </p>
        <p className={styles.bold}>3、我们尊重您对个人信息的管理</p>
        <p className={styles.bold}>
          我们承诺会公开明确地告知您我们收集、处理您个人信息的范围、原因及方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。
        </p>
        <p className={styles.bold}>4、我们将采取必要的措施保证您的个人信息安全</p>
        <p className={styles.bold}>
        我们承诺在能力范围内采取必要的安全措施，以保障您的个人信息安全，包括但不限于通过符合适用的法律法规及标准要求的硬件、软件、数据、人员、物理环境及其基础设施防护措施保护您的个人信息安全；不仅如此，我们还会要求相关员工进行保密并通过对员工进行安全意识培训，敦促其遵守本政策。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>二、我们收集、使用个人信息的目的及范围</p>
        <p>
        本政策所指的“个人信息”是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。当您访问、下载我们的平台时，或您使用我们的产品或服务时，您会向我们主动提供您的个人信息，我们也会通过自动收集工具、第三方收集您的信息。我们收集、使用个人信息的目的及范围如下：
        </p>
        <p>
        1、确定您的资信状况当您使用或开启相关功能或接受服务时，为确保功能、服务得以实施，我们只会收集必要个人信息。除非完全必要，否则大部分情况下您无需提供非必要个人信息。除非经过您的允许，否则应用不会开启非必要权限。即便开启了经过您授权的非必要权限，您也可以随时在本应用或设备操作系统中停止授权。为了帮助我们确认您的资信状况或用于可能的争议解决，我们基于用户填写的表单将收集并使用如下必要信息和非必要信息：
        </p>
        <p>
        （1）必要个人信息：当您开通借贷服务时，我们将收集您的真实姓名、身份证信息、手机号、并通过平台进行信息验证
        </p>
        <p>
        （2）申请评估借款额度时，需要进一步提供您的个人信息用以额度授信。您需要提供以下非必要信息：您的地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在本应用平台留下的注册信息（电话，电子邮箱）、您的IP地址、设备品牌、设备型号与我们的服务相关的日志信息。
        </p>
        <p>（3）调取的敏感权限</p>
        <p>
        我们收集上述信息，可能将调用您的相机权限、相册权限、地理位置权限。如您拒绝系统调用相关权限，我们将无法收集上述相应信息，可能导致系统无法正常提供服务。
        </p>
        <p>
          2、我们还可能为以下需要而保留、保存或披露您的个人信息：
        </p>
        <p>1）遵守适用的法律法规。</p>
        <p>2）遵守法院命令或其他法律程序的规定；</p>
        <p>3） 遵守相关政府机关的要求；</p>
        <p>4）为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们、其他用户的人身和财产安全或合法权益所合理必需的用途。</p>
        <p>3、为您提供借款、咨询及推荐服务</p>
        <p>为更优质和高效地向您提供我们的借款、咨询及推荐服务，我们将收集并使用您的下列信息：</p>
        <p>1）上述用于确定您的资信状况的信息；</p>
        <p>2）上述用于及时联系您以便处理您的投诉或提醒您履行合同义务；</p>
        <p>4、为您提高用户体验、个性化推荐、发送促销营销信息。</p>
        <p>
        为了向您发送服务状态通知、展现或推荐相关程度更高的产品、信息流或广告、推广信息及优惠信息或完善我们的营销体系（例如确保您需要的营销信息更加精准、便捷、高效地到达），我们将收集并使用您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。
        </p>
        <p>
        5、监测我们平台的性能和稳定性，优化性能、支持、改进服务质量。我们可能通过COOKIES等自动收集工具收集信息，包括您的IP地址、设备型号、设备品牌、操作系统版本、及我们的服务相关的日志信息。
        </p>
        <p>
        6、完善我们的内部风险控制为了帮助我们完善风控体系，自行或通过委托第三方建立数据模型或者建立性格测试模型，用于特征分析和用户画像，帮助我们进行内部风控，或我们通过技术手段对您的个人信息进行去标识化或匿名化处理的情况下，对该等数据的挖掘、分析/利用（包括商业化利用）和与第三方共享，我们将收集并使用您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。
        </p>
        <p>
          7、其他您个人信息的潜在用途
        </p>
        <p>
          （1） 预防或禁止非法的活动
        </p>
        <p>（2） 遵守法律法规的义务</p>
        <p>（3） 经您许可授权的其他用途</p>
        <p>当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</p>
        <p>当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。</p>
        <p>为实现应用消息推送功能，及跨应用打开本公司产品并完成页面还原功能，我们会共享必要信息给服务提供商</p>
        <table border={1} cellSpacing={0} cellPadding={4}>
          <tbody>
            <tr>
              <td className={styles.width80}>SDK 名称</td>
              <td>SDK 收集信息说明</td>
              <td className={styles.width80}>服务提供商</td>
            </tr>
            <tr>
              <td>华为推送SDK</td>
              <td>应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</td>
              <td>华为软件技术有限公司</td>
            </tr>
            <tr>
              <td>推送服务（OPPO PUSH）</td>
              <td>为向您提供推送服务，发送关于软件更新或新品发布等通知（包括但不限于我们及相关第三方产品或服务的营销信息），您同意我们暂存从开发者处获取的消息内容，并收集您的设备相关信息（如IMEI或OAID，Serial Number，IMSI，User ID，Android ID，Google Advertising ID, 手机Region设置，设备型号，手机电量，手机操作系统版本及语言）、使用推送服务的应用信息（如APP包名及版本号，运行状态）、推送SDK版本号、网络相关信息（如IP或域名连接结果，当前网络类型）、消息发送结果、通知栏状态（如通知栏权限、用户点击行为），锁屏状态（如是否锁屏，是否允许锁屏通知）。</td>
              <td>广东欢太科技有限公司</td>
            </tr>
            <tr>
              <td>小米消息推送服务</td>
              <td>小米推送（MiPush）是小米公司向开发者提供的消息推送服务，通过在云端与客户端之间建立一条稳定、可靠的长连接，为开发者提供向客户端应用实时推送消息的服务，有效地帮助开发者触达用户，提升App活跃度。 设备标识(OAID、加密的Android ID)、推送消息内容、设备信息(设备厂商、型号、归属地、运营商名称等)、通知栏设置</td>
              <td>北京小米移动软件有限公司</td>
            </tr>
            <tr>
              <td>vivo推送（即Vpush）</td>
              <td>vivo推送（即Vpush）是vivo公司向开发者提供的消息推送服务，通过在云端与客户端之间建立一条稳定、可靠的长连接，为开发者提供向客户端应用实时推送消息的服务，支持百亿级的通知/消息推送，秒级触达移动用户。 为将应用开发者提供的相关信息推送到您的设备终端，我们会收集您的设备标识信息（如IMEI、EmmCID、UFSID、ANDROIDID、GUID、GAID、OPENID、VAID、OAID、RegID、加密的Android ID）、使用推送服务的应用软件信息（如应用包名、版本号、APPID、安装、卸载、恢复出厂设置、运行状态）、设备制造商、网络类型、国家码、设备类型；</td>
              <td>维沃移动通信有限公司及其关联公司</td>
            </tr>
            <tr>
              <td>友盟消息推送服务</td>
              <td>产品集成友盟+SDK及推送通道SDK（如小米、华为、oppo、vivo、魅族等），推送通道SDK需要收集采集设备标识符（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM 卡 IMSI 信息等），用于唯一标识设备，以便向用户设备推送消息。采集地理位置甄别推送通道，提高消息推送的区域覆盖率</td>
              <td>友盟同欣（北京）科技有限公司、北京锐讯灵通科技有限公司</td>
            </tr>
          </tbody>
        </table>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>三、我们如何收集个人信息</p>
        <p>1、您直接提供给我们的信息</p>
        <p>
        您注册我们的会员账户、申请或使用我们的产品或服务，或通过电子邮件、电话或其他方式联系我们时，可能向我们提供一些个人信息。若您不提供这些信息或提供的信息有误，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p>2、我们主动收集的信息</p>
        <p>
        除了您提供给我们的个人信息外，我们可能直接通过COOKIES等自动收集工具收集您的信息，如您的IP地址、设备型号、设备品牌、操作系统版本、位置以及与我们的服务相关的日志信息。若您拒绝我们收集此类信息，我们将无法全部或部分为您提供相关产品或服务的功能，但不会影响我们向您提供核心的产品或服务的除外。
        </p>
        <p>3、设备权限调用</p>
        <p>
        在提供服务过程中，为了保证您能正常使用产品功能，我们会向系统申请您的以下设备权限，申请前我们会征询您的同意，您可以选择“允许”或“禁止”权限申请；申请成功后，您可以随时进入手机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用，请您理解：
        </p>
        <p>
          （1）为了给您提供身份验证、语音搜索服务，我们会向系统申请使用“相机权限”和“麦克风权限”，请您留意；
        </p>
        <p>（2）为了给您提供精准便捷的服务，我们会向系统申请“位置权限”；</p>
        <p>
         （3）为了能正常读取设备识别号码，保证您能正常保存图片、文件或缓存信息到手机时使用，我们会向系统申请“读取手机状态、启用存储读取权限”。为了给您提供更优质的服务，我们可能会根据情况向系统申请您的其他设备权限，如果您拒绝开启相应设备权限，您可以随时进入手机“设置-权限管理”中关闭相应权限，权限关闭后相关产品功能可能无法正常使用，请您理解。
        </p>
        <p className={styles.bold}>4、来自本公司产品合作伙伴的信息</p>
        <p className={styles.bold}>
        我们可能会从合作伙伴处获得您的信息，包括但不限于您在本公司合作公司所形成的产品或服务的表单信息及注册信息，您在其他商业合作伙伴处的交易及注册信息（表单信息）。若您拒绝授权或同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p className={styles.bold}>5、来自其他第三方的信息</p>
        <p className={styles.bold}>
        我们可能会通过其他第三方对您提供的信息进行验证，或者通过您提供的信息在第三方渠道查询您的其他信息，您的真实姓名、身份证信息 、地区、房产情况、车辆情况、公积金情况、月收入、收入形式、工作年限、学历、医社保情况、信用卡情况、芝麻分、京东白条情况、贷款额度、定位信息以及您在在本应用平台留下的注册信息（电话，电子邮箱）。若您拒绝授权或不同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>
        <p>6、在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需经过您的授权同意：</p>
        <p>1） 与国家安全、国防安全有关的；</p>
        <p>2） 与公共安全、公共卫生、重大公共利益有关的；</p>
        <p>3） 与犯罪侦查、起诉、审判和判决执行等有关的；</p>
        <p>4） 出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的；</p>
        <p>5） 所收集的个人信息是您自行向社会公众公开的；</p>
        <p>6） 从合法公开披露的信息中收集到的个人信息，如合法的新闻报道、政府信息公开等渠道；</p>
        <p>7） 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</p>
        <p>8） 法律法规规定的其他情形。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>四、我们如何存储个人信息</p>
        <p>1、保存的地域</p>
        <p>您的个人信息储存于中华人民共和国境内，我们不会在境外存储您的个人信息。</p>
        <p>2、保存的期限</p>
        <p>除非法律法规另有规定，我们将按如下方式及期间保存您的信息：</p>
        <p>
          1）您使用我们产品或服务期间，我们将综合考虑为您提供产品或服务的必要性，根据业务需要持续为您保存或删除个人信息；
        </p>
        <p>
          2） 但是，即便有第1） 款之规定，在我们不再为您提供产品或服务且在相关技术设置允许条件下，您有权联系我们注销账户并删除您的个人信息；
        </p>
        <p>
          3） 进一步的，即便有第2） 款之规定，在某些特定情况下，有且仅为了遵守国家安全、网络安全等法律规定的法定义务或政府命令，我们仍将继续保存您的信息，直至法定义务免除。
        </p>
        <p>3、跨境转移</p>
        <p>除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>五、我们如何共享、公开披露个人信息</p>
        <p className={styles.bold}>1、我们不会与任何公司、组织和个人分享您的个人信息，但以下情况除外：</p>
        <p className={styles.bold}>1） 在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
        <p className={styles.bold}>2） 我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
        <p className={styles.bold}>
          3）  与合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
        </p>
        <p>我们的合作伙伴包括：</p>
        <p>
        A、技术服务类合作伙伴。基于您的授权及设定，收取您的位置信息及设备信息并提供给技术服务商，特殊情境下会包含您另行填写的个人信息，以获取他们的支持，这些支持包括但不限于提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们线上服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查与分析。
        </p>
        <p>
        B、广告、数据分析类合作伙伴。 未经您的授权，我们不会将您的个人信息与提供广告、分析服务的非合作伙伴共享。但我们可能会将使用您的信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人身份信息的前提下提升广告及服务信息的精准度及有效触达率。
        </p>
        <p>2、我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>1） 在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
        <p>3、我们仅会在以下情况下，公开披露您的个人信息：</p>
        <p>1） 获得您明确同意后</p>
        <p>
          2） 基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>六、我们如何保护个人信息</p>
        <p className={styles.bold}>
        1、我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理合法可行的措施，保护您的个人信息，包括但不限于制定了严格的内部信息管理流程、对数据进行加密、定岗定责、控制访问权限、采取安全措施等，以防止其丢失、被非法使用、受到未授权访问或泄漏、被篡改或毁坏。
        </p>
        <p className={styles.bold}>
        2、我们会采取一切合理合法可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p className={styles.bold}>
        3、互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p className={styles.bold}>
        4、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        </p>
        <p className={styles.bold}>
        5、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p className={styles.bold}>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>七、我们如何使用COOKIES等自动收集工具和第三方插件</p>
        <p>
        （1）当您使用本公司产品时，我们使用COOKIES和其他类似技术来记录信息，您的IP地址、设备品牌、设备型号、操作系统版本、我们的服务相关的日志信息。此等技术帮助我们更好地了解用户的行为，告诉我们您浏览了我们平台的哪些部分，以及您正在处理的交易，并衡量广告和网络搜索的效果并加以改善。
        </p>
        <p>
        请您理解，我们平台中的许多COOKIES起着至关重要的作用：例如，当您登录我们账号时，COOKIES记录着您的账号信息，使您省去重复输入注册信息等步骤，或帮助您判断您的账户安全。其它的COOKIES帮助我们了解您使用我们平台的方式，从而使我们改进线上工作。对此我们使用相关的分析工具分析我们平台的加载时间、访客使用的方式，以及我们平台的访客查看最多的信息。它们还帮助我们发现我们平台中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的平台。
        </p>
        <p>
        您可以选择接受或拒绝所有COOKIES，或拒绝具体类别的COOKIES。如果您关闭COOKIES后，您仍可以使用我们平台的部分内容，但有些有用部分将不能使用，并且，将可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>八、您的权利保障机制</p>
        <p className={styles.bold}>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
        <p className={styles.bold}>1、查询您的个人信息</p>
        <p className={styles.bold}>您有权查询您的个人信息，法律法规规定的例外情况除外。如果您想查询您的个人信息，可以通过以下方式自行查询：</p>
        <p className={styles.bold}>
          1） 账户信息：如果您希望查询或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以通过访问执行此类操作。
        </p>
        <p className={styles.bold}>
        2） 搜索信息——您可以在本公司的平台中访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。
        </p>
        <p className={styles.bold}>如果您无法通过上述链接访问这些个人信息，您可以随时与我们联系。我们将及时回复您的访问请求。</p>
        <p className={styles.bold}>
          对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请联系我们。
        </p>
        <p className={styles.bold}>2、更正您的个人信息</p>
        <p className={styles.bold}>当您发现我们处理的关于您的个人信息有错误时，您有权联系我们做出更正。</p>
        <p className={styles.bold}>3、删除您的个人信息</p>
        <p className={styles.bold}>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p className={styles.bold}>1） 如果我们处理个人信息的行为违反法律法规</p>
        <p className={styles.bold}>2） 如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p className={styles.bold}>3） 如果我们处理个人信息的行为违反了与您的约定</p>
        <p className={styles.bold}>4） 如果我们不再为您提供产品或服务</p>
        <p className={styles.bold}>
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
        </p>
        <p className={styles.bold}>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
        <p className={styles.bold}>4、撤销您的个人信息</p>
        <p className={styles.bold}>
        每个业务功能需要一些基本的个人信息才能得以完成，您无法撤销正在进行的业务功能对应的信息。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
        </p>
        <p className={styles.bold}>如果您不想接受我们给您发送的商业广告，您随时联系我们取消。</p>
        <p className={styles.bold}>以APP为例，撤销信息授权的途径为：拨打我们提供的客服电话将您的撤销申请提交给我们。</p>
        <p className={styles.bold}>5、用户账户注销</p>
        <p className={styles.bold}>
          如果您与我们的权利义务均已享受或履行完毕、您注册以后不再需要我们提供的服务，您可以联系我们的客服人员进行用户账户的注销。
        </p>
        <p className={styles.bold}>6、响应您的上述请求</p>
        <p className={styles.bold}>
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        </p>
        <p className={styles.bold}>在以下情形中，我们将无法响应您的请求：</p>
        <p className={styles.bold}>1） 与个人信息控制者履行法律法规规定的义务相关的；</p>
        <p className={styles.bold}>2） 与国家安全、国防安全直接相关的；</p>
        <p className={styles.bold}>3） 与公共安全、公共卫生、重大公共利益直接相关的；</p>
        <p className={styles.bold}>4） 与犯罪侦查、起诉、审判和执行判决等直接相关的；</p>
        <p className={styles.bold}>5） 个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
        <p className={styles.bold}>6） 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
        <p className={styles.bold}>7） 响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
        <p className={styles.bold}>8） 涉及商业秘密的。</p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>九、免责声明（非常重要）</p>
        <p className={styles.bold}>
        互联网并非绝对安全的环境，可能存在或出现各种恶意或非恶意的攻击手段。您使用产品或服务时所用的系统和通讯网络，或硬件设备等我们均无法控制，请您了解并注意保护您的个人信息安全。虽然我们持续致力于提升和加强安全措施，以保护您的个人信息免遭意外或破坏，但仍无法始终保证您的个人信息的百分之百安全。
        </p>
        <p className={styles.bold}>
        请您注意，我们的网站、产品、应用软件和服务中可能含有第三方网站、产品或服务的链接。第三方收集的可能涉及您的个人信息，第三方须遵守关于个人信息的规定。我们希望你能了解这些第三方的隐私政策。我们会尽商业上的合理努力去要求这些第三方主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您不了解这些第三方创建的网页和开发的应用程序或发现他们存在风险时，建议您终止相关操作以保护您的合法权益。
        </p>
        <p className={styles.bold}>
        因信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。除非本政策有其他规定，为了保护您的人身及财产安全，我们特别提醒您不要通过公共网络，向我们或任何以我们名义为您提供服务的第三方，提供您的账户密码、验证码、通信内容等信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十、隐私政策的更新及生效</p>
        <p>
        本政策自发布之日起生效，如果发生业务功能变更、个人信息出境情况变更、个人信息使用目的变更、个人信息保护相关负责人联络方式变更等情形时，我们将对隐私政策进行相应的修订，届时我们会通过推送通知等方式告知您。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十一、未成年人个人信息特别约定</p>
        <p>
        我们非常重视未成年人的个人信息保护，我们的网站、应用及相关产品或服务均不以未成年人为目标客户。我们不会故意收集未成年人的任何个人信息，除非您主动提供且因获取某些产品或服务必须提供某些未成年人的必要信息外（例如您提供的房产信息可能包括未成年人信息）。可能导致系统无法正常提供服务。
        </p>
        <p>
        如果您是未成年人，请不要注册本公司产品，或不要以其它方式向我们提供任何您的个人信息。如果我们发现经疏忽获取了未成年人的个人信息，我们将尽快删除该等信息。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十二、您的申诉渠道和反馈机制</p>
        <p className={styles.bold}>
        如果您对本政策有任何疑问、意见或建议，或您在使用我们产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见、建议、投诉或举报，或您在使用产品或服务时遇到哪些问题，请联系我们，我们会在不超过15个工作日或法律法规要求的期限内同电话等方式及时答复您。
        </p>

        <div className={styles.emptyLine}></div>
        <p className={styles.bold}>十三、争议解决渠道</p>
        <p className={styles.bold}>
        当您以适当的方式开始、继续使用我们的产品或服务超过15日的，您未通过我们公布的联系方式（请您注意我们在平台公布的联系方式），就本政策的相关内容明示表示拒绝的，视为您充分理解并同意本政策。您理解并同意，在您明示拒绝前，我们依据本政策收集和使用的个人信息仍然有效。
        </p>
        <p className={styles.bold}>
        您和我们就本政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，您可以向有关行业协会（例如消费者协会等）、监管部门（网信办等）或权威部门投诉、举报，或者依据适用的法律向具有管辖权的法院提起诉讼。
        </p>
        <p className={styles.bold}>本协议生效时间：2023-03-01</p>
      </>
    )
  }

  return (
    <div className={classNames(styles.privacyAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="隐私协议" />
      }
      {
        channelValue?.includes('mjy') || channelValue?.includes('hbjf') ? mjyPrivacy()
          : youqianlaiPrivacy()
      }
    </div>
  )
}

export default PrivacyAgreement