/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef } from 'react'
import styles from './rematch-waiting.module.scss'
import Header from '@/components/header/header'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'

interface Props{}

const WaitingSuccess: React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const [search] = useSearchParams()
  const options:any = search.get('options')
  const resData = options && JSON.parse(decodeURIComponent(options))

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(3)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (countdownTime > 0) {
      timer.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1)
      }, 1000)
    } else {
      // 惠逸花授权页
      // if (resData.rqResult.thirdpartyType === 5) {
      //   window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(resData.rqResult))}&adChannelCode=${resData.adChannelCode}&isHuaWeiPhone=${resData.isHuaWeiPhone}&applyLimit=${resData.applyLimit}`)
      //   return
      // }
      if (channelValue?.includes('fenqile')) {
        window.location.replace(`/new-fill-information?options=${encodeURIComponent(JSON.stringify(resData))}`)
        return
      }
      // 公用授权页
      window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  return (
    <section className={styles.authorizePage}>
      <Header />
      { adChannelCode
        && channelValue?.includes('jufu')
        ? <img className={styles.rematchGif} src={require('@imgs/jufu/rematch.gif')} />
        : <img className={styles.rematchGif} src={require('@imgs/gif/rematch.gif')} />
      }
    </section>
  )
}

export default WaitingSuccess