/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useRef } from 'react'
import styles from './submit-waiting.module.scss'
import Header from '@/components/header/header'
import { useSearchParams, useNavigate } from 'react-router-dom'
import indexApi from '@/apis'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import sensors from 'sa-sdk-javascript'
import { SensorsType } from '@/constants/sensorsBurying'

interface Props{}

const WaitingSuccess: React.FC<Props> = () => {
  const { channelValue, adChannelCode } = useSelector((state: RootState) => state.channel)
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const options:any = search.get('options')
  const resData = JSON.parse(decodeURIComponent(options))

  const [canSubmitForm, setCanSubmitForm] = useState<boolean>(false)
  const [formResult, setFormResult] = useState<any>()
  const [hasEscalation, setHasEscalation] = useState<boolean>(false) //判断是否上报，上报过的就不再进行上报

  // 下载倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const submitFormAfter = (res: any) => {
    // 需要跳转授权页
    if (res && res.productName) {
      // if (res.thirdpartyType === 5) {
      //   window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(res))}&adChannelCode=${resData.adChannelCode}&isHuaWeiPhone=${resData.isHuaWeiPhone}&applyLimit=${resData.applyLimit}`)
      //   return
      // }
      // 跳转授权页
      let tempOptions = {
        adChannelCode: resData.adChannelCode,
        isHuaWeiPhone: resData.isHuaWeiPhone,
        applyLimit: resData.applyLimit,
        workCity: resData.workCity,
        rqResult: res
      }
      window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)
    } else {
      const query:any = res && res.productName ? res : { rePageShowType: resData.adChannelCode === '9o0w2B' && resData.isHuaWeiPhone ? 2 : 0 }
      window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(query))}`)
    }
  }

  // 倒计时
  useEffect(() => {
    const sourceType = search.get('sourceType')
    if (countdownTime > 0) {
      timer.current = setTimeout(() => {
        setCountdownTime(countdownTime - 1)
      }, 1000)
    } else if (sourceType === 'submit') { //如果当前页面需要提交表单
      // 表单返回结果，执行下一步
      if (formResult) {
        submitFormAfter(formResult)
      }
    } else {
      // 非提交表单跳转(普通跳转)
      window.location.replace(`/fill-information/common-authorize?options=${encodeURIComponent(JSON.stringify(resData))}`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime, formResult])

  // 触发提交表单操作
  useEffect(() => {
    const toSubmitForm = async(params:any) => {
      try {
        let res: any = await indexApi.saveCustomerInfo(params)
        // 神策埋点上报 - 用户点击提交表单
        sensors.track(SensorsType.click_submit_form_info, {
          ad_channel_code: adChannelCode || search.get('adChannelCode') || localStorage.getItem('ad_channel_code'),
        })
        // 百度埋点上报
        if (channelValue?.includes('bdVid') && !hasEscalation) {
          window._agl && window._agl.push(['track', ['success', { t: 3 }]])
          setHasEscalation(true)
        }
        // 报错表单提交结果
        setFormResult(res)
      } catch (error) {
        setTimeout(() => {
          navigate(-1)
        }, 3000)
      }
    }
    const sourceType = search.get('sourceType')
    if (sourceType === 'submit') {
      setCanSubmitForm(true) // 将canSubmitForm设置为true
      if (canSubmitForm) { // 这里用canSubmitForm，是不让submit重复提交
        toSubmitForm(resData)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmitForm])

  const getChannelTitle = () => {
    switch (true) {
      case channelValue?.includes('mjy'):
      case channelValue?.includes('hbjf'):
        // document.title = '\u200B'
        return ''
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      case channelValue?.includes('juhaojie'):
        return '聚好借'
      case channelValue?.includes('jufu'):
        return '聚客融'
      default:
        return '有钱钱包'
    }
  }

  return (
    <section className={styles.authorizePage}>
      <Header />
      {/* <img className={styles.progress2} src={require('@imgs/gif/progress-n.gif')} /> */}
      <div className={styles.headerBox}>
        <img className={styles.progress1} src={require('@imgs/gif/progress-1.gif')} />
      </div>
      <div className={styles.waitingText1}>您的信息正在认证中...</div>
      <div className={styles.waitingText2}>根据您的资料{ getChannelTitle()}来为您重新评估额度</div>
      <div className={styles.progressBar}>
        {
          channelValue?.includes('jufu')
            ? <img className={styles.progress2} style={{ marginTop: '30px' }} src={require('@imgs/jufu/progress-n.gif')} />
            : <img className={styles.progress2} src={require('@imgs/gif/progress-n.gif')} />
        }
      </div>
    </section>
  )
}

export default WaitingSuccess