/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import Header from '@/components/header/header'
import styles from './jkr-privacy-agreement.module.scss'
import classNames from 'classnames'
import { getParams, formatTime } from '@bihu/common-js'
import getHostNameObj from '@/hooks/get-host-name-obj'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { CompanyType } from '@/constants/common'

interface Props{}
// 聚客融隐私协议
const JukerongPrivacyAgreement: React.FC<Props> = () => {
  const { channelValue } = useSelector((state: RootState) => state.channel)
  const [isShowHeader, setIsShowHeader] = useState(false)
  useEffect(() => {
    if (getParams(undefined, 'isShowHeader')) {
      setIsShowHeader(true)
    }
  }, [])

  const getAgreementCompany = () => {
    switch (true) {
      case channelValue?.includes('jfxd'):
        return CompanyType.JFXD_COMPANY.company
      default:
        return CompanyType.JKR_COMPANY.company
    }
  }

  const signature = useMemo(() => {
    switch (true) {
      case channelValue?.includes('jurongyi'):
        return '聚融易'
      default:
        return '聚好借'
    }
  }, [channelValue])


  // 聚客融隐私协议
  const jkrPrivacy = () => {
    return (
      <>
        <p className={styles.bold}>
          {/* <br/> */}
            本版本更新日期：2024年05月10日
          <br/>
            本版本生效日期：2024年05月10日
          <br/>
          本隐私政策适用于【{signature}】APP及其H5链接 ，由{getAgreementCompany() }及合作持牌机构运营(以下简称“我们”)。我们将在本隐私政策中向您说明我们在为您提供服务时，如何处理您的个人信息，并提示您如何管理个人信息及享有的权利。
            我们建议您充分阅读并确保您理解本政策的全部内容。我们将尽可能使用简明扼要、通俗易懂的文字进行表述以方便您的理解，同时，我们将对关乎您权益处理以及个人敏感信息的相关条款进行加粗标记提示您注意。
            本隐私政策将帮助您了解以下内容，请您仔细阅读并确认知悉理解，当您自主勾选或点击同意按钮进行下一步时，意味着您自愿遵守本政策的全部内容，并接受本政策对您的约束：
          <br/>
            (1)我们收集您个人信息的目的和范围
          <br/>
            (2)我们如何收集您的个人信息
          <br/>
            (3)我们如何使用您的个人信息
          <br/>
            (4)我们如何使用Cookie和同类技术
          <br/>
            (5)我们如何处理您的个人信息
          <br/>
            (6)我们如何保存您的个人信息
          <br/>
            (7)我们如何保护您的个人信息
          <br/>
            (8)您可以如何管理您的信息
          <br/>
            (9)未成年人个人信息的保护
          <br/>
            (10)本隐私政策如何更新
          <br/>
            (11)如何联系我们
          <br/>
            (12)本政策的可分割性
          <br/>
            (13)其他
          <br/>
            我们承诺：
          <br/>
            1.所有涉及您个人信息(包括但不限于个人基本信息及敏感个人信息)的处理，我们将明示告知，并获得您的自主明示授权后进行处理。对于您的敏感个人信息我们将单独寻求您的同意，并告知您处理的必要性及使用目的。请您知悉，如您拒绝向我们提供您的敏感个人信息可能导致您无法使用与这些信息相关的平台功能，但不影响您使用我们提供的其他服务或功能。
          <br/>
            2.当您需使用APP内由第三方提供的产品或服务时，该第三方产品或服务将适用单独的第三方隐私政策，请您务必仔细阅读该第三方隐私政策或类似文件及声明，审慎做出决定。我们不会在未获得您的明示授权前与第三方主动共享您的任何信息。
          <br/>
            3.我们确保向您收集的信息是为了向您提供服务所需，我们会在获得您的明示同意后，严格遵循合法、正当、必要、诚信的原则收集、处理您的信息，您可以拒绝向我们提供这些信息。除法律法规规定的例外情况外，您可以要求查阅、复制、更正、限制处理、删除您的个人信息，撤回您的授权，注销您的账号，您可以通过本隐私政策的“十一、如何联系我们”中提及的联系方式与我们取得联系，行使相应的权利。
          <br/>
            4.在您注册并同意本政策前，您可随时在用户注册页面查看本隐私政策内容;在您注册并同意本政策后，您可随时通过“我的-设置-用户协议”查看隐私政策内容。
          <br/>
            5.您知晓并理解本平台将不断优化、迭代或新增为您提供的产品或者服务，我们也将据此不时更新本隐私政策。为保障您的个人信息权利，请您及时查阅最新版本的隐私政策。如果您不同意更新后的部分或全部内容、或对本隐私政策内容有任何疑问，您应立即暂停使用我们提供的产品或服务。如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请通过本隐私政策的“十一、如何联系我们”中提及的联系方式与我们取得联系，以便我们为您解答和说明。
          <br/>
            一、我们收集您个人信息的目的和范围
          <br/>
            1、我们仅会出于本政策所述的以下业务功能或目的，收集您的个人信息。我们承诺仅在具体的场景及步骤中，并在必要的范围内进行信息收集，具体使用场景、收集个人信息的内容、目的及收集方式列于附件一《收集个人信息说明清单》。如涉及敏感个人信息、第三方收集信息以及共享或转让您的个人信息等情形的，我们还将单独向您提示、依法进行说明和/或单独寻求您的同意。
            我们收集的目的包括但不限于：
          <br/>
            1.1用于实现反洗钱及实名认证。
          <br/>
            1.2用于为您提供贷款产品信息推荐服务、贷款信息咨询机构的产品展示、广告服务等。
          <br/>
            1.3用于在您明示同意的前提下，对您的资信情况及偿还能力进行预审核，并可能在您授权下共享给第三方合作机构，以为您更好的提供互联网贷款产品的推荐及匹配等服务。
          <br/>
            1.4基于您在使用本平台提供的服务中所做出的具体指令，为您提供订单状态提醒(如提现、还款、贷后管理)等提醒服务。
          <br/>
            其中，我们获取您的手机权限的场景和频率包括但不限于：
          <br/>
            访问位置信息权限
          <br/>
            应用场景：用于实现定位，获取位置信息
          <br/>
            使用频率：触发相关流程时获取
          <br/>
            读写手机存储权限
          <br/>
            应用场景：存储缓存数据
          <br/>
            使用频率：注册登录环节调用/触发相关流程时获取
          <br/>
            读取手机状态和身份权限
          <br/>
            应用场景：读取设备号、用于app装机数量的统计
          <br/>
            使用频率：触发相关业务时获取
          <br/>
            网络权限
          <br/>
            应用场景：获取软件安装列表，请求网络数据，app业务端数据正常展示，登录使用等场景使用
          <br/>
            使用频率：触发相关业务时获取
          <br/>
            相机权限
          <br/>
            应用场景：用于从相册中读取图片以及用于产品的拍照、视频录制的功能
          <br/>
            使用频率：触发相关业务时获取
          <br/>
            接收内部广播权限
          <br/>
            应用场景：页面展示刷新，接收推送的精准化，差异化服务使用
          <br/>
            使用频率：触发相关业务时获取
          <br/>
            安装权限
          <br/>
            应用场景：用于安装本app使用的权限
          <br/>
            使用频率：触发相关业务时获取
          <br/>
            2.您知悉、理解并同意，如您不同意提供您的个人信息，可能会影响您对本平台部分服务的使用，具体以实际服务页面所展示的为准，您同意您对此表示接受。
          <br/>
            二、我们如何收集您的个人信息
          <br/>
            在您自愿选择注册、自主同意填写个人信息以及使用我们所提供的产品或服务的过程中，我们将基于第一条所示明的目的和范围收集您自愿提供的信息。具体包括但不限于如下情形：
          <br/>
            2.1向您提供产品服务过程中您主动向我们提供的信息
          <br/>
            您通过注册本平台使用我们的服务，或通过电子邮件、电话等其他方式联系我们时，可能向我们提供一些个人信息。请您在主动填写相关信息或同意提供相关信息时关注信息内容，若您拒绝提供与核心业务功能相关的信息或提供的信息有误，可能导致我们无法提供核心产品或服务。
          <br/>
            2.2我们在您使用服务时获取的信息
          <br/>
            除了您提供给我们的个人信息外，为了满足提供服务的基本要求且为了保证在法律允许的范围内提供服务，保障您的账户安全与系统运行安全，我们会收集您在使用本平台产品或服务过程中产生的相关信息，以判断您的账户风险以及进行信贷资质预审核，保障我们为您正常提供服务，排查异常信息等。具体情形及范围请参照附件一《收集个人信息说明清单》，如您选择拒绝授权与核心业务功能相关的信息，可能导致我们无法提供核心产品或服务。
          <br/>
            2.3我们从第三方处获得的信息
          <br/>
            在您使用我们所提供的服务过程中，我们可能会在您的授权同意范围内，从信息核验服务提供商处获取并使用您的个人信息。在征得您的自主明示同意后，我们可能会将您提供的姓名、身份证号码、银行卡号、实名手机号等信息提供给信息核验服务提供商，以验证您身份及提供信息的真实性，防范欺诈、账号盗用等风险。在前述情形下，我们会要求该服务商说明您的个人信息来源，对合法性进行确认，并确认提供方已获得的个人信息处理的同意范围，包括但不限于处理目的、处理方式，以及您是否同意对外提供等，如在您使用我们所提供的服务过程中需进行的个人信息处理活动超出您原本向第三方提供个人信息时的同意范围，我们将在处理您的该等个人信息前，征得您的明示同意，法律法规另有规定的除外。
          <br/>
            2.4征得授权同意的例外
          <br/>
            根据相关法律法规的规定，在以下情形中，我们可能会依法处理您的必要个人信息且无需征得您的同意：
          <br/>
            2.4.1与国家安全、国防安全直接相关的;
          <br/>
            2.4.2与公共安全、公共卫生、重大公共利益直接相关的;
          <br/>
            2.4.3与犯罪侦查、起诉、审判和判决执行等直接相关的;
          <br/>
            2.4.4出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
          <br/>
            2.4.5所收集的个人信息是您自行向社会公众公开的;
          <br/>
            2.4.6从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道;
          <br/>
            2.4.7根据您的要求签订和履行合同所必需的;
          <br/>
            2.4.8用于维护本平台的产品/服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障;
          <br/>
            2.4.9法律法规规定的其他情形。
          <br/>
            三、我们如何使用您的信息
          <br/>
            3.1为了充分保证您的合法权益，为您提供更优质的产品推荐及信息展示服务及最大程度的保证您的个人信息数据安全等原因，您知悉理解并确认接受我们将您明示授权的信息用作下列用途：
          <br/>
            1)实现本隐私政策及附件一《收集个人信息说明清单》所述目的，包括但不限于进行用户身份识别、资料核查和验证，以审核您的履约情况、信用情况，确保交易的合理性、安全性和防范违法犯罪活动;
          <br/>
            2)基于您的明示同意，为了为您提供更优质、个性化的服务、内容和建议，向我们合作的第三方共享您的信息;
          <br/>
            3)用于预防、发现及处理非法或违反与我们的协议、政策或规则的行为，以保护您的合法权益或用以解决争议、对纠纷进行调停;
          <br/>
            4)为了保证您能够正常使用我们提供的服务。您同意，在您注册本平台后突然中断服务/产品使用或长时间停止使用本平台的服务/产品的情况下，我们或我们合作的第三方会通过站内信、电子邮件、电话、短信、等方式向您提供、发送服务状态的通知、营销活动或其他商业性信息。如不需要我们推广相关信息，您可以联系客服取消、退订该类信息推广服务;
          <br/>
            5)根据法律法规或监管要求向相关部门进行报告;
          <br/>
            6)为实现本政策目的所需的、或者本政策或其他协议中经您授权许可的其他使用方式。
          <br/>
            3.2如我们基于使用您的信息进行用户身份识别、资料核查等情形，根据核验结果变更为您提供的服务，或多次为您提供您已使用的服务的目的，再次或多次使用您的信息的，我们会通过更新本隐私政策或在显著位置展示以征求您的授权同意。由于多次使用我们已收集的您的个人信息是实现该业务功能的必要前提，如果不提供信息，我们可能无法向您提供对应的服务。
          <br/>
            3.3特别说明
          <br/>
            3.3.1如您向我们合作的第三方申请有关贷款产品，且相关申请工作是在本平台页面内完成，您需要授权我们将您的【设备信息】、【基本信息】、【位置信息】、【面部信息】向第三方提供。第三方基于自身风险评估策略将获取的您的信息进行存储、整理、分析及使用。
          <br/>
            3.3.2如您向第三方申请有关贷款产品，且相关申请工作是需要跳转至该第三方的网站或页面完成申请工作的，您可能需要根据第三方要求，自行跳转至其网站或页面向第三方提供有关信息，我们不会收集您在此种情况下提供的信息，也不会在没有您明示授权的前提下将您提供给我们的信息向第三方提供。
          <br/>
            特别提醒您，我们会要求第三方按照法律规定来管理您的信息，但这并不能免除您通过第三方的网站或页面提供信息可能会给您带来不利的影响。
          <br/>
            3.3.3不同贷款产品需要提供的信息会有所不同，请以申请时第三方的具体要求为准。您可以拒绝提供相关信息，但您可能也因此无法获取该等产品或服务。
          <br/>
            3.3.4为了您的信息安全，我们会采取脱敏、去标识化等方式对您的信息进行内部分类及分析加工。为了使您知晓使用我们服务的状态，我们会向您发送服务提醒。如您选择不接收服务提醒消息，您可以联系客服退订服务提醒消息。
          <br/>
            四、我们如何使用Cookie和同类技术
          <br/>
            4.1 Cookie
          <br/>
            为确保【{signature}】APP正常运转，同时为了根据您的实际需求进行咨询或数据分析、改善产品服务及用户体验、提高广告效果、及时发现并防范安全风险等原因，您同意我们有权使用诸如“Cookie”等装置以搜集、追踪相关信息和资料并提供只有通过“Cookie”等装置才可得到的功能。“Cookies”是设置在您的硬盘上的小档案，以协助我们为您提供度身订造的服务。我们还利用“Cookies”使您能够在某段期间内减少输入密码的次数。“Cookies”还可以协助我们提供专门针对您的兴趣而提供的资料。
            我们不会将“Cookie”用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除“Cookie”。
          <br/>
            4.2 Cookie同类技术
          <br/>
            除Cookie外，我们还在【{signature}】APP上使用了Local Storage技术，主要是用来做本地用户数据存储，前后页面数据传递以及页面数据渲染。Local storage是Cookie的同类技术，仅在Chrome里面存储数据，不能在IE或者Fire Fox等其他浏览器访问。我们使用Local Storage技术，常用来帮助我们记录您登录的时间和状态、简化您重复登录的步骤、分析您使用我们网站的情况，从而帮助您获得更轻松的访问体验。我们不会将Local storage用于本隐私政策所述目的之外的任何用途。您可以根据自己的偏好管理或清除本网站内保存的所有Local Storage，如果您清除这些Local storage，您之前所记录的相应信息也均会被删除，这可能会对您所使服务的安全性和便捷性有影响。
          <br/>
            五、我们如何处理您的个人信息
          <br/>
            5.1共享
          <br/>
            我们不会主动与第三方共享您的个人信息，基于为您提供服务的需要，我们在对外向任何第三方共享您的信息及数据前，我们会征得您的明示同意。我们承诺会根据相关法律法规及监管规定对您的个人信息承担保密义务。
            我们将会在以下情形下将您的信息向第三方进行共享(以下合称“共享目的”)，基于对我们合作的第三方的监管义务，我们将促使该第三方在经授权使用您的信息过程中对您的信息采取适当保密与安全措施，您因第三方未妥善履行保密义务而遭受损失的，我们将协助您向该第三方寻求赔偿。涉及对外共享用户个人信息的第三方类型、信息范围、共享目的、联系方式等具体情况详见《用户个人信息共享授权协议》及本隐私政策后附件二《共享个人信息说明》，共享情形包括但不限于：
          <br/>
            5.1.1事先获得您的明确同意或授权;
          <br/>
            5.1.2根据可适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下;
          <br/>
            5.1.3供依法设立的征信机构和个人信用数据机构依法查询和使用;
          <br/>
            5.1.4在法律法规允许的范围内，为维护我们或我们合作的第三方，您或其他用户，或社会公众利益、财产或安全免遭损害而有必要提供;
          <br/>
            5.1.5如您选择通过我们为您提供贷后管理及相关服务;
          <br/>
            5.1.6为符合我们、我们合作的第三方与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定及履行所必需而提供;
          <br/>
            5.1.7如我们涉及合并、分立、收购、资产或业务转让或类似的交易，您的个人信息可能作为此类交易的一部分而被共享，我们将确保该等信息在共享时的机密性，您同意对此予以认可和理解;
          <br/>
            5.1.8为实现本政策目的所需的、或者本政策或其他协议中经您授权许可的其他共享方式;
          <br/>
            5.2转让
          <br/>
            我们不会主动将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          <br/>
            5.2.1事先取得您的明确同意/授权后;
          <br/>
            5.2.2根据可适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供;
          <br/>
            5.2.3符合我们或我们合作的第三方与您签署的相关协议(包括在线签署的电子协议以及相应的平台规则)或其他的法律文件约定所提供;
          <br/>
            5.2.4在本产品服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
          <br/>
            5.3公开披露
          <br/>
            原则上我们不会将您的信息进行公开披露，但可能基于以下目的披露您的个人信息：
          <br/>
            5.3.1在获取您明确同意或基于您的主动选择的情况下 可能会公开披露您的个人信息;
          <br/>
            5.3.2遵守适用的法律法规等有关规定;
          <br/>
            5.3.3遵守法院判决、裁定或其他法律程序的规定;
          <br/>
            5.3.4遵守相关政府机关或其他法定授权组织的要求;
          <br/>
            5.3.5我们有理由确信需要遵守法律法规等有关规定;
          <br/>
            5.4针对以下情形，您知悉处理您的个人信息无需事先征得您的授权同意：
          <br/>
            5.4.1与国家安全、国防安全有关的;
          <br/>
            5.4.2与公共安全、公共卫生、重大公共利益有关的;
          <br/>
            5.4.3与犯罪侦查、起诉、审判和判决执行等有关的;
          <br/>
            5.4.4出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;
          <br/>
            5.4.5您自行向社会公众公开的个人信息;
          <br/>
            5.4.6从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
          <br/>
            5.4.7对于您在本平台的已经去标识化处理的个人信息，依据法律法规的规定，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
          <br/>
            六、我们如何保存您的个人信息
          <br/>
            6.1保存地域
          <br/>
            您的个人信息将储存于中华人民共和国境内，本隐私政策项下，您的个人信息不涉及跨境传输。若将来涉及跨境传输且确需向境外机构传输您的个人信息之时，我们会按照法律、行政法规和相关监管部门的规定执行，并在数据跨境传输前再次征求您的同意和允许或进行匿名化处理，方可进行数据传输。同时，我们确保会在隐私政策中详细说明数据传输的地点和用途，让您随时了解个人信息的存储位置。且我们为确保您的个人信息得到足够的保护，将会在跨境数据转移之前将您的个人信息采取如加密传输等安全技术措施。
          <br/>
            6.2保存期限
          <br/>
            除在您正常使用我们的产品或服务期间，我们会对您明示授权后我们收集的信息进行存储，无论何种原因，您的账户进行注销后，我们将及时对您在我们平台的所有信息进行匿名化处理，除法律法规另有规定外，我们将对您注销账户后的匿名化个人信息进行最长不超过5年的存储。
          <br/>
            七、我们如何保护您的个人信息
          <br/>
            7.1个人信息保护措施
          <br/>
            我们非常重视个人信息安全，将采取一切合理可行的技术安全措施，保护您的个人信息以防止信息的丢失、不当使用、未经授权的访问或披露：
          <br/>
            7.1.1为了保障您的信息安全，我们在收集您的信息后，将采取各种合理必要的措施保护您的信息。例如，在技术开发环境当中，我们仅使用经过去标识化处理的信息进行统计分析;我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不重新识别个人。我们建立了数据分级制度，对您的手机号、身份证号等个人敏感信息全程进行加密处理，并设置严格的数据访问权限，确保您的个人信息安全;
          <br/>
            7.1.2为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件(SSL)进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。传输和存储个人敏感信息(含个人生物识别信息)时，我们将采用加密、权限控制、去标识化等安全措施;
          <br/>
            7.1.3我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则;对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导;
          <br/>
            7.1.4尽管我们采取了上述技术安全措施，但请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能因超出我们的控制能力之外的其他因素而出现安全问题。如您发现自己的个人信息泄密，尤其是您的账户或密码发生泄漏，您应该立即通过{signature}客服与我们取得联系，以便我们采取相应措施;
          <br/>
            7.1.5在您终止使用我们的服务后，我们会停止对您的信息的收集，法律法规或监管部门另有规定的除外。您也可以依据本政策第8.3条和8.5条的规定要求删除个人信息或要求注销账户。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。
          <br/>
            7.2安全事件
          <br/>
            7.2.1不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低风险的建议、对您的补救措施。我们会及时将事件相关情况以站内通知、短信通知、电话、邮件您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告;
          <br/>
            7.2.2我们会及时处置系统漏洞、网络攻击、病毒侵袭及网络侵入安全风险。在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施，并按照规定向有关主管部门报告;
          <br/>
            7.2.3如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或损毁，导致您的合法权益受损，我们将严格依照法律的规定承担相应的责任;
          <br/>
            八、您可以如何管理您的信息
          <br/>
            我们非常重视并保护您关于管理个人信息的权利，并为您提供了管理个人信息的方法。您有权利访问、更正、撤销、复制、删除自己的信息，请求解释说明并保护自己的隐私和安全。
          <br/>
            8.1访问权
          <br/>
            除法律法规规定的例外情况，无论您何时使用我们的服务，我们都会力求让您顺利访问您的个人信息。您可通过{signature}App的“我的-账号设置”、“我的-我的客服”(本隐私政策简称“在线客服")分别访问或管理您的账户中的个人资料和信息。
          <br/>
            8.2更正权/补充权
          <br/>
            当您需要补充、更新您的个人信息时，或发现我们处理您的个人信息有错误时，您有权做出更正或更新、补充。您可以自行在APP端进行更正，或通过反馈与报错将您的更正申请提交给我们。在您进行更新或更正前，我们可能会先要求您验证自己的身份，然后再处理您的请求。
          <br/>
            8.3删除权
          <br/>
            在以下情形中，您可以向我们提出删除个人信息的请求：
          <br/>
            8.3.1如果我们处理个人信息的行为违反法律法规;
          <br/>
            8.3.2如果我们收集、使用您的个人信息，却未征得您的同意;
          <br/>
            8.3.3如果我们处理个人信息的行为违反了与您的约定;
          <br/>
            8.3.4如果您不再使用我们的产品或服务，或您注销了账号且符合本隐私政策的其他规定;
          <br/>
            8.4改变您授权同意的范围
          <br/>
            每个业务功能需要您开启部分权限并收集必要的个人信息才能得以实现，对于您个人信息的收集、使用以及设备权限的管理，您可以通过本隐私政策第十一条联系我们改变您在本隐私政策下授权同意的范围或撤回您的授权，或通过本隐私政策中提供的以下方式自行操作关闭相应的设备权限：
          <br/>
            8.4.1如您的设备为iOS系统，则您可通过"设置-隐私"管理您授权我们的设备权限;
          <br/>
            8.4.2如您的设备为安卓系统，则您可通过“设置-应用-权限管理”，管理您授予我们的设备权限;
          <br/>
            当您撤回同意或授权后，我们将无法继续为您提供撤回同意或授权所对应的服务，同时我们将不再处理相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。您知悉并理解，在您撤回同意或授权前提供的个人信息，我们将继续依照可适用的法律法规规定及本政策进行保存或披露。
          <br/>
            8.5注销权
          <br/>
            您可以通过“我的-设置-注销账号"或通过本隐私政策第十一条联系我们注销账户。当您注销平台账户后，我们将停止为您提供服务且我们将不会再收集、使用或对外提供与该账户相关的个人信息，您在注册至注销期间的所有信息，我们将对您在本平台的全部信息删除或进行匿名化处理。我们不会对您的注销行为设置不必要或不合理的条件或障碍。
          <br/>
            8.6约束信息系统自动决策
          <br/>
            当我们为您提供根据您主动填写的信息进行个性化服务或其他类似服务时，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式及便捷的拒绝方式。您可以通过本隐私政策第十一条联系我们。
          <br/>
            8.7响应您的上述请求以及解释说明权
          <br/>
            如果您无法通过上述方式访问、更正、删除、改变您授权同意的范围，或者您无法完成账户注销，或者需要获取个人信息副本;或者您就我们收集、使用、共享、保护您的个人信息存在任何疑问，您都可以通过本隐私政策第十一条联系我们。
            我们将尽合理商业努力，满足您对于个人信息的访问、更正、删除、改变您授权同意的范围及账户注销的要求。但对于无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
            对于您合理的请求，我们不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。
            按照法律法规要求，以下情况中，我们将无法响应您的请求：
          <br/>
            8.7.1与履行法律法规规定的义务相关的;
          <br/>
            8.7.2与国家安全、国防安全直接相关的;
          <br/>
            8.7.3公共安全、公共卫生、重大公共利益直接相关的;
          <br/>
            8.7.4与刑事侦查、起诉、审判和执行判决等直接相关的;
          <br/>
            8.7.5有充分证据表明您存在主观恶意或滥用权利的;
          <br/>
            8.7.6出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的;
          <br/>
            8.7.7响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的;
          <br/>
            8.7.8涉及商业秘密的。
          <br/>
            九、未成年人个人信息的保护
          <br/>
            以下条款请未成年用户(特别是未满十四周岁的儿童用户)在监护人的陪同下仔细阅读，并由监护人在充分知悉和理解后作出相应决定。
            我们的产品和服务仅面向年满十八周岁，具有完全民事权利能力和一定偿还能力的自然人，且不得为在校学生。如果您是未成年人，请不要尝试注册或以任何形式使用我们的平台服务及产品，也请不要以任何方式向我们提供您的任何个人信息。
            一经我们发现，我们将无需征得您的同意注销您的账户，终止为您提供任何服务。
          <br/>
            十、本隐私政策如何更新
          <br/>
            10.1本隐私政策将会随着我们业务的发展进行更新，以便为您提供更好的服务。未经您的明示同意，我们不会削减您按照本隐私政策应享有的权利。如本隐私政策发生更新，我们会在【{signature}】APP上及时发布更新版本并通过包括但不限于公告、弹窗或以其他适当方式提醒您相关内容的更新，请您及时访问以便了解最新的隐私政策。
          <br/>
            10.2对于本隐私政策的重大变更，我们会提供显著的通知，本政策所指的重大变更包括但不限于：
          <br/>
            10.2.1我们的服务模式发生重大变化：如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等发生变化;
          <br/>
            10.2.2我们在控制权方面发生重大变化：如并购重组引起的所有者变更;
          <br/>
            10.2.3个人信息委托处理、共享、转让或公开披露的主要对象发生变化;
          <br/>
            10.2.4您参与个人信息处理方面的权利及其行使方式发生重大变化;
          <br/>
            10.2.5我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时;
          <br/>
            10.2.6个人信息安全影响评估报告表明存在高风险时;
          <br/>
            10.2.7其他可能对您的个人信息权益产生重大影响的变化。
          <br/>
            我们会将旧版本的隐私政策进行存档，您可以通过本政策(十一、如何联系我们)与我们联系并查阅。
            本政策更新后我们将按照新的隐私政策提供产品或服务，如果您在更新后继续使用{signature}的产品或服务，则视为您已充分阅读并接受本政策相关条款的修改。
          <br/>
            十一、如何联系我们
          <br/>
            11.1个人信息保护相关负责人
          <br/>
            我们设置了专职个人信息保护相关负责人，个人信息保护相关负责人的联系邮箱为【kefu@jukerong.com】。如您对我们收集、使用、共享、保护您的个人信息有任何疑问等，您都可以通过个人信息保护相关负责人的邮箱或者{signature}客服电话(4008-116-568)或在线客服与我们联系。
          <br/>
            11.2特别提示
          <br/>
            11.2.1一般情况下，您联系我们后，我们将在[10]个工作日内回复您，协助解决您的问题。
          <br/>
            11.2.2部分情况下，为保障您个人信息的安全，我们可能需要您提供身份证明、有效联系方式和书面请求，及其他有效证据。我们将在收到您的反馈并验证您的身份后尽快答复您的相关请求。
          <br/>
            11.2.3公司联系地址如下：
          <br/>
            地址：深圳市南山区招商街道花果山社区公园路25号四海宜家(北.南)座南座202-3
          <br/>
            十二、其他
          <br/>
            12.1本政策采用电子合同的方式进行签订，一经您自主勾选同意即视为生效。本政策适用于平台向您提供的所有服务，但需要特别说明的是：本政策不适用于由第三方合作机构直接向您提供的产品或服务。
          <br/>
            12.2如果本政策的任何条款在任何程度上被认定无效或不可执行，本政策的其余部分不应受其影响且应在法律允许的最大程度内予以执行。
          <br/>
            12.3本隐私政策与【{signature}】APP的《用户注册服务协议》中规定的个人信息保护条款存在不一致或矛盾之处，请以本隐私政策的条款为准。
          <br/>
            12.4双方一致同意可将争议提交至深圳仲裁委员会仲裁裁决。
          <br/>
            授权人手机号：【】
          <br/>
          签署日期：【{ formatTime(Date.now(), 'YYYY')}】年【{ formatTime(Date.now(), 'MM')}】月【{ formatTime(Date.now(), 'DD')}】日
          <br/>
            附件一：《收集个人信息说明清单》
          <br/>
            序号：1
          <br/>
            收集信息内容：蜂窝网络IP/ID(iOS);防网络代理(iOS)
          <br/>
            使用目的：检测网络环境;安全恢复设备ID
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：启动/打开App
          <br/>
            序号：2
          <br/>
            收集信息内容：设备信息：网络数据链接(安卓);UUID(安卓);蜂窝网络IP/ID(iOS);设备的AndroidID(安卓);IP地址;WiFi数据(安卓、iOS);iOS设备广告追踪ID;防网络代理(iOS);手机号、验证码
          <br/>
            使用目的：注册账号/登录App;检测网络环境安全;恢复设备ID;防止欺诈风险、落实业务风险防控
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：注册/登录
          <br/>
            序号：3
          <br/>
            收集信息内容：位置信息;IP地址;设备信息：设备品牌;设备型号;是否越狱;总储存空间大小;可使用储存空间大小;总内存空间大小;可使用内存控件大小;iOS设备广告追踪ID
          <br/>
            使用目的：助平台合作方判断是否向您提供贷款服务;防止欺诈风险、落实业务风险防控
          <br/>
            避免您的账号被非法分子盗用进而导致您账号中的信息泄露;检测网络环境安全
          <br/>
            恢复设备ID
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：首页
          <br/>
            序号：4
          <br/>
            收集信息内容：基本信息：手机号、姓名、身份证号、身份证有效期、年龄、民族、户籍所在地、手机号归属地、学历、月收入、工作单位名称
          <br/>
            联系人信息：联系人一关系、联系人一姓名、联系人一手机号、联系人二关系、联系人二姓名、联系人二手机号
          <br/>
            补充信息：负债情况、所属行业、居住城市
          <br/>
            位置信息：位置信息、IP地址
          <br/>
            手机号在网时长
          <br/>
            使用目的：助平台合作方判断是否向您提供贷款服务
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：风险评估
          <br/>
            序号：5
          <br/>
            收集信息内容：身份证OCR人像面照片、身份证OCR国徽面照片;
          <br/>
            姓名、年龄、性别、民族、身份证号、身份证有效期、身份证户籍地址、身份证发证机关
          <br/>
            使用目的：完成反洗钱义务及实名制管理;实名制认证以及授信额度评估，客户身份识别
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：实名认证
          <br/>
            序号：6
          <br/>
            收集信息内容：人脸识别照片
          <br/>
            使用目的：进行实名验证，降低借贷风险
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：人脸识别
          <br/>
            序号：7
          <br/>
            收集信息内容：基本信息：学历、月收入、职业类型、保公积金、房产车产、社寿险保单、工作单位名称、工作单位所在城市、工作单位详细地址、工作单位电话
          <br/>
            联系人信息：联系人一关系、联系人一姓名、联系人一手机号、联系人二关系、联系人二姓名、联系人二手机号
          <br/>
            补充信息：婚姻、借款用途、负债情况、住房类型、所属行业、居住城市、居住地址、电子邮箱
          <br/>
            银行卡信息：银行卡号、银行卡所属银行、银行预留手机号
          <br/>
            位置信息：位置信息、IP地址
          <br/>
            申请信息：申请金额、申请期限
          <br/>
            设备信息：网络数据;手机系统版本号(安卓);运行内存空间(安卓);设备的AndroidID(安卓);手机分辨率(安卓);手机型号(安卓);手机品牌(安卓);UUID(安卓：主板、系统制造商、CPU指定级、设置参数、硬件制造商、系统版本、手机制造商、用于拼UUID设备标识符);是否root(安卓);是否越狱(iOS);IP地址;WiFi数据;设备IMEI号;总储存空间大小;可使用储存空间大小;总内存空间大小;可使用内存控件大小;iOS设备广告追踪ID(iOS)
          <br/>
            使用目的：用于向您推荐合适的金融产品;保障可以正常使用提供的产品或服务，防止您的个人信息被获取，更准确地预防欺诈和保护您的账户安全
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：额度申请
          <br/>
            序号：8
          <br/>
            收集信息内容：交易记录查询：交易信息、申请信息、审批信息、放款信息、还款信息
          <br/>
            订单状态提醒;逾期还款提醒：手机号码
          <br/>
            使用目的：为了便于您通过本平台查阅您的贷款申请
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：贷款管理
          <br/>
            序号：9
          <br/>
            收集信息内容：订单状态：申请状态、准入状态、进件状态、申请授信评估中、授信拒绝、授信通过、逾期
          <br/>
            交易信息、订单信息、浏览信息、关注信息
          <br/>
            "设备信息：蜂窝网络IP/ID;SIM卡序列号;设备的AndroidID;Mac地址;IP地址;WiFi网关;设备IMEI号;WiFi的BSSID;WiFi的SSID;蓝牙Mac地址;设备IMSI;设备序列号;补充设备标识符OAID;总储存空间大小;可使用储存空间大小;总内存空间大小
          <br/>
            可使用内存控件大小;iOS设备的基站信息MCC及MNC;iOS设备广告追踪ID;iOS设备应用开发商标识符"
          <br/>
            使用目的：为了更好地为您提供通知服务，提供我们或我们关联方、合作方的商品、服务或推广活动、商业广告、并通过站内信息、短信方式、发送服务状态通知、电话的方式明示通知您
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：商业资讯推送
          <br/>
            序号：10
          <br/>
            收集信息内容：姓名、手机号码、身份证号、电子邮件及其他联系方式以及您与客服练习室提供的其他相关信息;在线聊天记录
          <br/>
            使用目的：提供客服服务
          <br/>
            收集方式：用户明示授权
          <br/>
            服务场景：客户服务
          <br/>
            附件二：《共享个人信息说明》
          <br/>
            共享目的：征信检测
          <br/>
            共享信息类型：个人基本信息
          <br/>
            共享信息字段：姓名、身份证号、手机号
          <br/>
            共享频次：申请贷款时共享一次
          <br/>
            处理方式：{signature}提供，信息接收方向{signature}返回评价结果
          <br/>
            信息接收方名称：百融至信(北京)征信有限公司、北京荣达天下信息科技有限公司
          <br/>
            接收方隐私政策：http://www.brgroup.com
          <br/>
            接收方联系电话：010-64718828
          <br/>
            共享目的：
          <br/>
            共享信息类型：个人基本信息
          <br/>
            共享信息字段：身份证OCR图片、人脸识别图片
          <br/>
            共享频次：进行身份认证、人脸识别时共享一次
          <br/>
            处理方式：储存ORC和活体图片
          <br/>
            信息接收方名称：深圳市腾讯计算机系统有限公司|腾讯云计算(北京)有限责任公司|上海七牛信息技术有限公司
          <br/>
            接收方隐私政策：
          <br/>
            https://www.qiniu.com/agreements/privacy-righthttps://privacy.qq.com/document/preview/a76038ae5ee242d18d3a45a34cdd744c
          <br/>
            接收方联系电话：400-801-3260
          <br/>
            共享目的：推送服务信息
          <br/>
            共享信息类型：个人基本信息、借款信息
          <br/>
            共享信息字段：姓名、手机号、金额、订单状态
          <br/>
            共享频次：常规短信使用时共享一次，放款/还款短信共享上述所有字段
          <br/>
            处理方式：{signature}提供，信息接收方使用
          <br/>
            信息接收方名称：广州市玄武无线科技股份有限公司
          <br/>
            接收方隐私政策：
          <br/>
            广州玄武：https://www.wxchina.com/
          <br/>
            接收方联系电话：
          <br/>
            广州玄武：4001000566
          <br/>
            共享目的：电子合同签约系统
          <br/>
            共享信息类型：个人基本信息
          <br/>
            共享信息字段：姓名、身份证号、手机号
          <br/>
            共享频次：申请指定贷款时共享一次
          <br/>
            处理方式：{signature}提供，信息接收方拟定单子签约合同，并生成电子签约合同
          <br/>
            信息接收方名称：浙江爱签数字科技有限公司
          <br/>
            接收方隐私政策：https://www.asign.cn/privacypolicy/
          <br/>
            接收方联系电话：400-7800-699
          <br/>
            共享目的：借款服务
          <br/>
            共享信息类型：个人基本信息、设备信息
          <br/>
            共享信息字段：
          <br/>
            个人基本信息：手机号、姓名、年龄、性别、民族、身份证号、身份证有效期、身份证户籍地址、身份证发证机关、身份证人像面照片、身份证国徽面照片、人脸识别照片、学历、月收入、职业类型、保公积金、房产车产、社寿险保单、工作单位名称、工作单位所在城市、工作单位详细地址、工作单位电话、联系人一关系、联系人一姓名、联系人一手机号、联系人二关系、联系人二姓名、联系人二手机号、婚姻、借款用途、负债情况、住房类型、所属行业、居住城市、居住地址、电子邮箱、银行卡号、银行卡所属银行、银行预留手机号、位置信息
          <br/>
            设备信息：网络数据、手机系统版本号(安卓)、运行内存空间(安卓)、设备的AndroidID(安卓)、手机分辨率(安卓)、手机型号(安卓)、手机品牌(安卓)、UUID(安卓：主板、系统制造商、CPU指定级、设置参数、硬件制造商、系统版本、手机制造商、用于拼UUID设备标识符)、是否root(安卓)、是否越狱(iOS)、IP地址、WiFi数据、设备IMEI号、总储存空间大小、可使用储存空间大小、总内存空间大小、可使用内存控件大小、iOS设备广告追踪ID(iOS)
          <br/>
            共享频次：申请贷款时共享一次
          <br/>
            处理方式：{signature}提供，信息接收方向{signature}返回申请结果
          <br/>
            信息接收方名称：第三方借款机构
          <br/>
            共享目的：
          <br/>
            共享信息类型：当前地理位置
          <br/>
            共享信息字段：ip
          <br/>
            共享频次：申请贷款时共享一次
          <br/>
            处理方式：{signature}提供，信息接收方向{signature}返回评价结果
          <br/>
            信息接收方名称：高德软件有限公司、北京荣达天下信息科技有限公司
          <br/>
            接收方隐私政策：
          <br/>
            高德：https://cache.amap.com/h5/h5/publish/238/index.html 联系电话：4008100080
          <br/>
            ip 数据云：https://www.ipdatacloud.com/statement/privacy 联系电话：19306238583
          <br/>
            埃文科技：https://www.ipplus360.com/privacy-policy 联系电话：400-0371-911
          <br/>
            共享目的：
          <br/>
            共享信息类型：人脸信息
          <br/>
            共享信息字段：人脸信息
          <br/>
            共享频次：申请时候共享
          <br/>
            处理方式：{signature}提供，信息接收方使用
          <br/>
            信息接收方名称：广州市玄武无线科技股份有限公司
          <br/>
            接收方隐私政策：
          <br/>
            广州玄武：https://www.wxchina.com/
          <br/>
            接收方联系电话：
          <br/>
            广州玄武：4001000566
          <br/>
        </p>
      </>
    )
  }

  return (
    <div className={classNames(styles.privacyAgreement, isShowHeader && styles.isShowHeader)}>
      {
        isShowHeader && <Header title="隐私协议" />
      }
      {
        jkrPrivacy()
      }
    </div>
  )
}

export default JukerongPrivacyAgreement